import React, { useState, useEffect, useRef, useMemo, useCallback  } from "react";
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
    getPaginationRowModel,
    getSortedRowModel,
    getFilteredRowModel,
} from "@tanstack/react-table";
import { useDrag, useDrop } from "react-dnd";
import Pagination from "../../components/table/Pagination";
import sortIcon from "../../assets/icons/sort-icon.svg";
import filterIcon from "../../assets/icons/filter-icon.svg";
import xMarkIcon from "../../assets/icons/X-mark icon.svg";
import ModifyViewButton from "./ModifyViewButton";
import NoRecords from "../../components/alerts/NoRecords";
import RequestFailed from "../../components/alerts/RequestFailed";
import Loader from "../../components/ui/Loader";
import ReportSavingPage from "./ReportSavingPage";
import ReportEditPage from "./ReportEditPage";
import { useNavigate } from "react-router-dom";
import GTM from "../../GTM";
import Divider from "../../components/ui/Divider";
import useOutsideClick from "../../hooks/useOutsideClick";
import {
    absenceType,
    leaveType,
    paymentHistoryType,
    disabilityType,
    changesReportType
} from "../../constants";
import { useFlags } from "launchdarkly-react-client-sdk";
import ReportButtons from "../../components/ui/ReportButtons";
import ExportToCSV from "./ExportToCSV";
function ReportTable({
    claimData,
    defaultColumns,
    isError,
    isFetching,
    isUninitialized,
    handleOptionChange,
    allViewsData,
    handleViewReportSave,
    loaderStatus,
    handleViewReportUpdate,
    handleCoverageType,
    selectedReportView,
    notificationStatus,
    description,
    selectedViewId,
    handleViewID,
    reportViewType,
    reportLastFetched,
    userEmail,
    portalUserApiError,
    organizationsAccess,
    emitFiltersDataForSelectedView,
    selectedFilterStatus,
    reportingPeriodValue,
    reportingStatusValue,
    selectedCoverageType,
    selectedPolicyDivision,
    includeHistoricalLeaveValue,
    periodScheduleTypeValue,
    periodStatusValue,
    protectionStatusValue,
    reportSortingColumnDirection,
    reportSortingColumnName,
    disclaimer,
    totalResults,
    portalUserData,
    totalPages,
    pagecounter,
    isDataFormattingComplete,
    columnFilters,
    setColumnFilters
}) {
    const navigate = useNavigate();
    const gtm = new GTM(null);
    const exportRef = useRef();
    const [checkColumnOrder, setCheckColumnOrder] = useState([]);
    const [columnVisibility, setColumnVisibility] = useState([]);
    const [columnOrderChange, setColumnOrderChange] = useState(true);
    const [showEditPage, setShowEditPage] = useState(false);
    const [sortingColumnDetails, setSortingColumnDetails] = useState();
    const [reportViewDropDownValues, setReportViewDropDownValues] = useState(
        []
    );
    const [updateViewDetails, setUpdateViewDetails] = useState([]);
    const [selectedReport, setselectedReport] = useState(null);
    const [selectedViewDataType, setSelectedViewDataType] =
        useState(reportViewType);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [columnResizeMode, setColumnResizeMode] = useState("onChange");

    const [ssnDisplayMode, setSSNDisplayMode] = useState(0);
   
    const { allPagingCounts, limitTableFunctionalityCount, filterIconVisibility } = useFlags();
    const [pagingCount, SetPagingCount] = useState(null);

    useEffect(() => {
        if (reportViewType === leaveType) {
            SetPagingCount(allPagingCounts?.leave);
        }
        if (reportViewType === disabilityType) {
            SetPagingCount(allPagingCounts?.claim);
        }
        if (reportViewType === changesReportType) {
           SetPagingCount(allPagingCounts?.changes);
            
        }
        if (reportViewType === paymentHistoryType) {
            SetPagingCount(allPagingCounts?.payments);
        }
        if (reportViewType === absenceType) {
            SetPagingCount(allPagingCounts?.claim);
        }
    }, [allPagingCounts]);
    
    const reorderColumn = (draggedColumnId, targetColumnId, columnOrder) => {
        setColumnOrderChange(false);
        columnOrder.splice(
            columnOrder.indexOf(targetColumnId),
            0,
            columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0]
        );
        return [...columnOrder];
    };

    const [columns, setColumns] = useState([...defaultColumns]);

    const DraggableColumnHeader = ({ header, table, activeFilterColumn, toggleFilterPopup}) => {
        const { getState, setColumnOrder } = table;
        const { columnOrder } = getState();
        setCheckColumnOrder(columnOrder);
        const { column } = header;
        const [, dropRef] = useDrop({
            accept: "column",
            drop: !largeDataSet ? (draggedColumn) => {
                const newColumnOrder = reorderColumn(
                    draggedColumn.id,
                    column.id,
                    columnOrder
                );
                setColumnOrder(newColumnOrder);
                if (newColumnOrder[0] === columnOrder[columnOrder.length - 1]) {
                    scrollToHorizontal();
                }
            } : () => {},
        });
        const isFilterOpen = activeFilterColumn === header.column.id;
        const iconRef = useRef(null);
        const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });

        const handleFilterClick = (columnId) => {
            if (iconRef.current) {
                const iconRect = iconRef.current.getBoundingClientRect();

                const newPosition = {
                    top: iconRect.bottom,
                    left: iconRect.left,
                }
                setPopupPosition(newPosition);
            }
            toggleFilterPopup(columnId);
        };

        const [{ isDragging }, dragRef] = useDrag({
            collect: (monitor) => ({
                isDragging: largeDataSet ? false : monitor.isDragging(),
            }),
            item: largeDataSet
                ? null
                : () => column, 
            type: "column",
        });

        const toggleSSNVisibility = () => {
            let newMode;

            if (organizationsAccess.SSNACCESS === "Partial") {
                newMode = (ssnDisplayMode + 1) % 2;
            } else if (organizationsAccess.SSNACCESS === "Full") {
                newMode = (ssnDisplayMode + 1) % 3;
            }

            setSSNDisplayMode(newMode);
        };

        const getSSNIcon = () => {
            let icon = null;

            switch (organizationsAccess?.SSNACCESS) {
                case "No Access":
                    break;
                case "Partial":
                    switch (ssnDisplayMode) {
                        case 0:
                            icon = (
                                <i
                                    className="fa fa-eye mask-eye cursor-pointer ml-4p"
                                    style={{ color: "#037cb7" }}
                                ></i>
                            );
                            break;
                        case 1:
                            icon = (
                                <i
                                    className="fa fa-eye-slash full-eye cursor-pointer ml-4p"
                                    aria-hidden="true"
                                ></i>
                            );
                            break;
                        default:
                            break;
                    }
                    break;
                case "Full":
                    switch (ssnDisplayMode) {
                        case 0:
                            icon = (
                                <i
                                    className="fa fa-eye part-eye cursor-pointer report-ssn-icon ml-4p"
                                    style={{ color: "#037cb7" }}
                                ></i>
                            );
                            break;
                        case 1:
                            icon = (
                                <i className="fa fa-eye mask-eye cursor-pointer ml-4p"></i>
                            );
                            break;
                        case 2:
                            icon = (
                                <i
                                    className="fa fa-eye-slash full-eye cursor-pointer ml-4p"
                                    style={{ color: "#037cb7" }}
                                    aria-hidden="true"
                                ></i>
                            );
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }

            return icon;
        };

        const scrollToHorizontal = () => {
            const tableContainer = document.querySelector(
                ".custom-table-container"
            );
            if (!tableContainer) return;
            const scrollAmount =
                tableContainer.scrollWidth - tableContainer.clientWidth;
            tableContainer.scrollTo({ left: scrollAmount, behavior: "smooth" });
        };

        const inputRef = useRef();

        useEffect(() => {
            if (isFilterOpen) {
                inputRef.current.focus();
            }
        }, [isFilterOpen]);

        const handleFilterChange = useCallback((e) => {
            column.setFilterValue(e.target.value);
        }, [column]);

        return (
            <th
                ref={largeDataSet ? null : dropRef}
                colSpan={header.colSpan}
                style={{
                    opacity: isDragging ? 0.5 : 1,
                    minWidth: header.column.columnDef.minSize,
                    maxWidth: header.getSize(),
                    paddingLeft: header.index === 0 ? 24 : 8,
                    position: 'relative',
                }}
            >
                <div className="report-header d-flex">
                    <div
                        ref={largeDataSet ? null : dragRef}
                        onClick={largeDataSet ? null : header.column.getToggleSortingHandler()}
                        onKeyDown={(e) => {
                            if (
                                !largeDataSet &&
                                e.key === "Enter" &&
                                header.column.getToggleSortingHandler
                            ) {
                                header.column.getToggleSortingHandler()(e);
                            }
                        }}
                        style={{
                            backgroundColor: "white",
                            wordBreak: "break-all",
                            textWrap: "nowrap",
                            textAlign: "left",
                        }}
                    >
                        {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                            )}
                        {!largeDataSet && 
                        sorting !== undefined &&
                        sorting.length > 0 &&
                        sorting[0].id === column.id ? (
                            sorting[0].desc ? (
                                <i
                                    style={{ marginLeft: 4, color: "#025780" }}
                                    className="fa-solid fa-arrow-down-wide-short "
                                ></i>
                            ) : (
                                <i
                                    style={{ marginLeft: 4, color: "#025780" }}
                                    className="fa-solid fa-arrow-up-short-wide"
                                ></i>
                            )
                        ) : (
                            !largeDataSet && <img src={sortIcon} alt="Sort" />
                        )}
                    </div>
                    <div>
                        <div>
                            {header.column.id === "SSN" && (
                                <div
                                    onClick={toggleSSNVisibility}
                                    style={{ cursor: "pointer" }}
                                >
                                    {!isUninitialized &&
                                        !isFetching &&
                                        !isError &&
                                        data.length > 0 &&
                                        ssnValues[0] !== "No Access" &&
                                        getSSNIcon()}
                                </div>
                            )}
                        </div>
                    </div>
                    {(!largeDataSet && !isUninitialized && !isFetching && !isError && data.length > 0 && filterIconVisibility) && (
                        <div style={{ marginLeft: '2px', cursor: 'pointer', position:"relative"}}
                            onClick={() => handleFilterClick(header.column.id)}>
                            {table.getColumn(column.id)?.getFilterValue() && !isFilterOpen &&  <i class="fa-solid fa-circle-check" style={{color:"#5E9E42",position:"absolute",top:"-6px",left:7}}></i>}
                            <img 
                                src={filterIcon} 
                                alt="Filter" 
                                ref={iconRef}
                                style={{
                                    filter: isFilterOpen ? 'invert(46%) sepia(27%) saturate(450%) hue-rotate(175deg) brightness(94%) contrast(95%)' : 'none'
                                }}
                            />
                        </div>
                    )}
                    {isFilterOpen && (
                        <div style={{position:"relative"}}>
                            <div className="filter-popup-box section-card" style={{
                                top: `${popupPosition.top + 10}px`,
                                left: `${popupPosition.left}px`,
                            }}>
                                <div className="top-line">
                                    <div className="title">
                                        <img src={filterIcon} alt="Filter" />
                                        <span className="text" >
                                            Filter {header.column.columnDef.header}
                                        </span>
                                    </div>
                                    <div className="close-area" onClick={() => toggleFilterPopup(header.column.id)}>
                                        <span className="close-text">Close</span> 
                                    </div>
                                </div>
                            
                                <div className="filter-box">
                                    <i
                                        className="fa fa-search search"
                                    ></i>
                                    <input
                                        ref={inputRef}
                                        type="text"
                                        value={column.getFilterValue() ?? ''}
                                        onChange={handleFilterChange}
                                        onKeyDown={(e) => handleEnterKeyPress(e, header.column.id)}
                                        placeholder={`Filter ${header.column.id}`}
                                        className="input-box"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div
                    onDoubleClick={header.getResizeHandler()}
                    onMouseDown={header.getResizeHandler()}
                    onTouchStart={header.getResizeHandler()}
                    className={`resizer ${
                        header.column.getIsResizing() ? "isResizing" : ""
                    }`}
                />
            </th>
        );
    };

    const [data, setData] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [pageNumber, setPageNumber] = useState(0);
    const [isfetchingMoreData, setIsFectchingMoreDate] = useState(false);
    const largeDataSet = data.length > limitTableFunctionalityCount;

    useEffect(() => {
        setData(claimData);
    }, [claimData]);
    
    useEffect(() => {
        if (totalPages > pagecounter) {
            setIsFectchingMoreDate(true);
        }
        if (totalPages <= pagecounter) {
            setIsFectchingMoreDate(false);
        }
    }, [totalPages, pagecounter]);

    const [columnOrder, setColumnOrder] = useState(
        columns.map((column) => column.id)
    );

    useEffect(() => {
        setColumns([...defaultColumns]);
    }, [defaultColumns]);

    const [sorting, setsorting] = useState([
        { id: reportSortingColumnName, desc: reportSortingColumnDirection },
    ]);

    useEffect(() => {
        setsorting([
            { id: reportSortingColumnName, desc: reportSortingColumnDirection },
        ]);
    }, [reportSortingColumnName, reportSortingColumnDirection]);

    const [showLoader, setShowLoader] = useState(false);
    const [show, setShow] = useState(false);

    const handleItemsPerPageChange = (selectedReport) => {
        setItemsPerPage(selectedReport);
        setPageNumber(0);
    };
    useEffect(() => {
        setPageNumber(0);
    }, [isFetching]);
    const handlePageNumberClick = (pageNumber) => {
        setPageNumber(pageNumber - 1);
    };
    const handleSortingChange = (newSorting) => {
        setsorting(newSorting);
    };

    const [activeFilterColumn, setActiveFilterColumn] = useState(null);

    const toggleFilterPopup = (columnId) => {
        if (activeFilterColumn === columnId) {
            setActiveFilterColumn(null);
        } else {
            setActiveFilterColumn(columnId);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            const tableElement = document.getElementById("reportingData");
            if (tableElement && !tableElement.contains(event.target)) {
                setActiveFilterColumn(null);
            }
        };
    
        document.addEventListener("mousedown", handleClickOutside);
        
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleEnterKeyPress = (event, columnId) => {
        if (event.key === 'Enter') {
          toggleFilterPopup(columnId);
        }
      };

    const memoizedColumns = useMemo(() => {
        return columns.map((column) => ({
            ...column,
            enableResizing: !largeDataSet,
        }));
    }, [columns]);

    const table = useReactTable({
        data,
        columns: memoizedColumns,
        state: {
            sorting,
            columnOrder,
            pagination: {
                pageIndex: pageNumber,
                pageSize: itemsPerPage,
            },
            columnVisibility,
            columnFilters,
        },
        columnResizeMode,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        onColumnOrderChange: setColumnOrder,
        getFilteredRowModel: getFilteredRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: false,
        debugHeaders: false,
        debugColumns: false,
        onSortingChange: handleSortingChange,
    });

    const getTotalWidth = (columns) => {
        return columns
            .filter(column => column.getIsVisible())
            .reduce((total, column) => {
                return total + (column.getSize() || 0);
            }, 0);
    };

    const activeColumnWidth = getTotalWidth(table.getAllColumns());

    // setting defult view in dropdown
    useEffect(() => {
        const keyValuePair = allViewsData.map((obj) => {
            return {
                value: obj.Name,
                label: obj.Name,
                key: obj.ViewId,
            };
        });
        setselectedReport(keyValuePair[0]);

        const coverageType = allViewsData.map((obj) => obj.CoverageType)[0];
        handleCoverageType(coverageType);
    }, []);

    // selecting dropdown value
    const handleSelectChange = (selectedReport) => {
        handleOptionChange(selectedReport);
        setselectedReport(selectedReport);

        setIsDropdownOpen(!isDropdownOpen);
        setSSNDisplayMode(0);
        setsorting([
            { id: reportSortingColumnName, desc: reportSortingColumnDirection },
        ]);
        const matchedObject = allViewsData.find(
            (obj) => obj.ViewId === selectedReport.key
        );
        setColumnOrder(matchedObject.ColumnInformation.map((obj) => obj.id));
        handleCoverageType(matchedObject.CoverageType);
        emitFiltersDataForSelectedView(matchedObject);
        setSelectedViewDataType(matchedObject.DataViewType);
        gtm.Event(
            "custom_event",
            "dropdown",
            "select",
            "change report",
            "report detail",
            matchedObject.DataViewType,
            "field_interaction",
            ""
        );
    };

    useEffect(() => {
        if (selectedReport) {
            handleViewID(selectedReport.key);
        }
    }, [handleViewID, selectedReport]);

    // hidecolumns

    useEffect(() => {
        if (selectedReport && selectedReport !== undefined) {
            const matchedObject = allViewsData.find(
                (obj) => obj?.ViewId === selectedReport?.key
            );

            const getColumnVisibility = matchedObject?.ColumnInformation;

            if (getColumnVisibility && getColumnVisibility !== undefined) {
                const hide = {};

                getColumnVisibility.forEach((item) => {
                    hide[item.id] = item.checked;
                });

                setColumnVisibility(hide);
            }
            setSelectedViewDataType(matchedObject?.DataViewType)
        }
    }, [allViewsData, selectedReport]);

    //used for onclick on report on most used reports
    useEffect(() => {
        if (selectedReportView && selectedReportView !== undefined) {
            const keyValuePair = {
                value: selectedReportView.Name,
                label: selectedReportView.Name,
                key: selectedReportView.ViewId,
            };
            setColumnOrder(
                selectedReportView.ColumnInformation.map((obj) => obj.Id)
            );
            setselectedReport(keyValuePair);
            handleCoverageType(selectedReportView.CoverageType);
            emitFiltersDataForSelectedView(selectedReportView);
        }
    }, [selectedReportView]);

    const handleResetButton = () => {
        setColumnOrderChange(true);
        setColumnOrder(columns.map((column) => column.id));
        table.resetColumnFilters();
        const resetColumnOrder = {};
        defaultColumns.forEach((item) => {
            resetColumnOrder[item.id] = item.checked;
        });
        setColumnVisibility(resetColumnOrder);
        gtm.Event(
            "custom_event",
            "button",
            "click",
            "reset",
            "report detail",
            selectedViewDataType,
            "field_interaction",
            ""
        );
    };

    const handleSaveButton = () => {
        setShow(true);
        const matchedObject = allViewsData.find(
            (obj) =>
                obj.ViewId === selectedReport.key &&
                obj.OwnerType !== "Standard"
        );
        if (matchedObject && matchedObject.OwnerType === "Personal") {
            setShowEditPage(true);
            setUpdateViewDetails(matchedObject);
        } else {
            setShowEditPage(false);
        }
        gtm.Event(
            "custom_event",
            "button",
            "click",
            "save",
            "report detail",
            selectedViewDataType,
            "field_interaction",
            ""
        );
    };

    const handleReportViewDontSave = () => {
        setShow(false);
    };

    const handleReportViewSave = async (viewName, viewDescription) => {
        const changedColumnSorting = checkColumnOrder
            .map((id, index) => {
                const matchedObject = sorting.find((obj) => obj.id === id);
                if (matchedObject) {
                    return {
                        ColumnDisplayOrderId: index + 1,
                        Direction: matchedObject.desc ? "DESC" : "ASC",
                    };
                } else {
                    return null;
                }
            })
            .filter((obj) => obj !== null);
        const changedColumnOrder = checkColumnOrder
            .map((id, index) => {
                const matchedObject = defaultColumns.find(
                    (obj) => obj.id === id
                );

                if (matchedObject) {
                    return {
                        DisplayOrder: index + 1,
                        AccessorKey: matchedObject.accessorKey,
                        Id: matchedObject.id,
                        Header: matchedObject.header,
                        Checked:
                            columnVisibility[id] === undefined
                                ? true
                                : columnVisibility[id],
                        LockedDisplay: matchedObject.enableHiding,
                        Size: matchedObject.size,
                        MinSize: matchedObject.minSize,
                        MaxSize: matchedObject.maxSize,
                    };
                } else {
                    return null;
                }
            })
            .filter((obj) => obj !== null);
        setColumnOrderChange(true);
        setShow(false);
        handleViewReportSave(
            changedColumnSorting,
            changedColumnOrder,
            viewName,
            viewDescription
        );
        const savedViewValue = {
            value: viewName,
            label: viewName,
        };
        setselectedReport(savedViewValue);
    };

    useEffect(() => {
        setselectedReport((prev) => ({
            ...prev,
            key: selectedViewId,
        }));
    }, [selectedViewId]);

    useEffect(() => {
        let keyValuePair;
        if (selectedReportView && selectedReportView.DataViewType) {
            keyValuePair = allViewsData
                .filter(
                    (obj) =>
                        obj.DataViewType === selectedReportView.DataViewType
                )
                .map((obj) => {
                    return {
                        value: obj.Name,
                        label: obj.Name,
                        key: obj.ViewId,
                        ownerType: obj.OwnerType,
                    };
                });
        } else {
            // If selectedReportView is empty, map allViewsData without filtering
            keyValuePair = allViewsData.map((obj) => {
                return {
                    value: obj.Name,
                    label: obj.Name,
                    key: obj.ViewId,
                    ownerType: obj.OwnerType,
                };
            });
        }
        setReportViewDropDownValues(keyValuePair);
    }, [allViewsData, selectedReportView]);

    useEffect(() => {
        if (loaderStatus === true) {
            setShowLoader(true);
        } else {
            setShowLoader(false);
        }
    }, [loaderStatus]);

    const handleReportViewUpdate = (viewName, viewDescription, viewId) => {
        const changedColumnSorting = checkColumnOrder
            .map((id, index) => {
                const matchedObject = sorting.find((obj) => obj.id === id);
                if (matchedObject) {
                    return {
                        ColumnDisplayOrderId: index + 1,
                        Direction: matchedObject.desc ? "DESC" : "ASC",
                    };
                } else {
                    return null;
                }
            })
            .filter((obj) => obj !== null);
        const changedColumnOrder = checkColumnOrder
            .map((id, index) => {
                const matchedObject = defaultColumns.find(
                    (obj) => obj.id === id
                );
                if (matchedObject) {
                    return {
                        DisplayOrder: index + 1,
                        AccessorKey: matchedObject.accessorKey,
                        Id: matchedObject.id,
                        Header: matchedObject.header,
                        Checked:
                            columnVisibility[id] === undefined
                                ? true
                                : columnVisibility[id],
                        LockedDisplay: matchedObject.enableHiding,
                        Size: matchedObject.size,
                        MinSize: matchedObject.minSize,
                        MaxSize: matchedObject.maxSize,
                    };
                } else {
                    return null;
                }
            })
            .filter((obj) => obj !== null);
        handleViewReportUpdate(
            changedColumnSorting,
            changedColumnOrder,
            updateViewDetails,
            viewName,
            viewDescription
        );
        setShow(false);
        const savedViewValue = {
            value: viewName,
            label: viewName,
            key: viewId,
        };
        setselectedReport(savedViewValue);
    };

    const handleManageReportsClick = () => {
        let data = [];
        navigate("/reportingcapabilities/manageviews", {
            state: { data, portalUserData, isError },
        });
        gtm.Event(
            "custom_event",
            "button",
            "click",
            "manage my views",
            "report detail",
            selectedViewDataType,
            "navigation_click",
            ""
        );
    };

    useEffect(() => {
        const res = {};
        for (const item of defaultColumns) {
            const { id, checked } = item;
            if (columnVisibility.hasOwnProperty(id)) {
                if (columnVisibility[id] !== checked) {
                    res[id] = checked;
                }
            }
        }
        if (Object.keys(res).length > 0) {
            setColumnOrderChange(false);
        } else {
            setColumnOrderChange(true);
        }
    }, [columnVisibility]);

    useEffect(() => {
        if (sorting[0]) {
            gtm.Event(
                "custom_event",
                "button",
                "click",
                "sort",
                "report detail",
                selectedViewDataType,
                "field_interaction",
                sorting[0].id
            );
        }
        const isMatching = sorting.some(
            (item) =>
                item.id === reportSortingColumnName &&
                item.desc === reportSortingColumnDirection
        );
        setSortingColumnDetails(isMatching);
    }, [sorting]);

    const dropdownRef = useRef(null);
    const toggleRef = useRef(null);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const isClickedOutside = useOutsideClick(dropdownRef, toggleRef);

    useEffect(() => {
        if (isClickedOutside) {
            setIsDropdownOpen(false);
        }
    }, [isClickedOutside]);

    const groupedOptions = {};
    reportViewDropDownValues.forEach((option) => {
        if (!groupedOptions[option.ownerType]) {
            groupedOptions[option.ownerType] = [];
        }
        groupedOptions[option.ownerType].push(option);
    });
    
    
 
    const ssnValues = table
        .getRowModel()
        .rows.map((row) => row.renderValue("SSN"));

    const getSSNDisplay = (cell, index) => {
        // Define a mapping object for organizationsAccess.SSNACCESS levels

        const ssnAccessLevels = {
            "No Access": () => {
                if (cell.column.id === "SSN") {
                    return "No Access";
                }
                return flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext()
                );
            },
            Partial: () => {
                if (cell.column.id === "SSN") {
                    if (ssnDisplayMode === 0) {
                        return "***-**-****";
                    } else if (ssnDisplayMode === 1) {
                        return ssnValues[index]?.substr(-4);
                    } else {
                        return "";
                    }
                }
                return flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext()
                );
            },
            Full: () => {
                if (cell.column.id === "SSN") {
                    if (ssnDisplayMode === 0) {
                        return "***-**-****";
                    } else if (ssnDisplayMode === 1) {
                        return ssnValues[index]?.substr(-4);
                    } else if (ssnDisplayMode === 2) {
                        return ssnValues[index];
                    } else {
                        return "";
                    }
                }
                return flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext()
                );
            },
        };

        const displaySSN = ssnAccessLevels[organizationsAccess?.SSNACCESS];

        return displaySSN
            ? displaySSN()
            : flexRender(cell.column.columnDef.cell, cell.getContext());
    };

    const topScrollbarRef = useRef(null);
    const tableContentRef = useRef(null);
    const topScrollBarContentRef = useRef(null);
    const iconAdjustmentWidth = 40;
    let getCenterTotalSize = table.getCenterTotalSize();

    useEffect(() => {
        const topScrollbar = topScrollbarRef.current;
        const tableContent = tableContentRef.current;
        if (!topScrollbar || !tableContent) {
            return;
        }
        const syncScrollFromTable = () => {
            if (topScrollbar && tableContent) {
                topScrollbar.scrollLeft = tableContent.scrollLeft;
            }
        };

        const syncScrollFromTopScrollbar = () => {
            if (topScrollbar && tableContent) {
                tableContent.scrollLeft = topScrollbar.scrollLeft;
            }
        };

        const updateScrollbarWidth = () => {
            if ((topScrollbar && tableContent) || getCenterTotalSize) {
                const totalTableWidth = table.getCenterTotalSize() + iconAdjustmentWidth;
                topScrollbar.firstChild.style.width = `${totalTableWidth}px`;
            }
        };

        // Initial width update
        updateScrollbarWidth();

        // Adding event listeners for scroll synchronization
        tableContent.addEventListener("scroll", syncScrollFromTable);
        topScrollbar.addEventListener("scroll", syncScrollFromTopScrollbar);

        // Cleanup event listeners on component unmount
        return () => {
            tableContent.removeEventListener("scroll", syncScrollFromTable);
            topScrollbar.removeEventListener(
                "scroll",
                syncScrollFromTopScrollbar
            );
        };
    }, [topScrollbarRef, tableContentRef, isFetching, showLoader, isDataFormattingComplete]);

    const [exportData, setExportData] = useState(false);
    const handleExport = () => {
        setExportData(true);
    };

    useEffect(() => {
        if (exportData && exportRef.current) {
            exportRef.current.exportToExcel(); // Call the export function
            setExportData(false); // Reset the state to prevent repeated calls
        }
    }, [exportData]);

    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    return (
        <>
            {exportData && (
                <ExportToCSV
                    ref={exportRef}
                    data={data}
                    table={table}
                    defaultColumns={defaultColumns}
                    selectedReport={selectedReport}
                    reportLastFetched={reportLastFetched}
                    userEmail={userEmail}
                    portalUserApiError={portalUserApiError}
                    reportingPeriodValue={reportingPeriodValue}
                    reportingStatusValue={reportingStatusValue}
                    selectedCoverageType={selectedCoverageType}
                    selectedViewDataType={selectedViewDataType}
                    includeHistoricalLeaveValue={includeHistoricalLeaveValue}
                    periodScheduleTypeValue={periodScheduleTypeValue}
                    periodStatusValue={periodStatusValue}
                    protectionStatusValue={protectionStatusValue}
                    disclaimer={disclaimer}
                    organizationsAccess={organizationsAccess}
                    columnVisibility={columnVisibility}
                    columnOrder={columnOrder}
                    ssnDisplayMode={ssnDisplayMode}
                    onExport={handleExport}
                />
            )}
            <div
                className="section-card row d-flex "
                style={{ padding: 0, margin: 0 }}
            >
                {showLoader ? (
                    <Loader text="Saving" />
                ) : (
                    <div className="p-0">
                        <div
                            style={{
                                paddingLeft: 24,
                                paddingRight: 24,
                                paddingTop: 24,
                            }}
                        >
                            <div
                                className="d-flex justify-content-between report-table"
                                style={
                                    isFetching || isfetchingMoreData
                                        ? {
                                              pointerEvents: "none",
                                              opacity: "0.4",
                                          }
                                        : {}
                                }
                            >
                                <div className="d-flex gap-3">
                                    <div
                                        className="report-view-dropdown"
                                        ref={dropdownRef}
                                    >
                                        <div
                                            className="d-flex justify-content-between  align-items-center cursor-pointer"
                                            style={{ width: 320 }}
                                            ref={toggleRef}
                                            onClick={toggleDropdown}
                                        >
                                            <div className="dropdown-header">
                                                {selectedReport?.label
                                                    ? selectedReport?.label
                                                    : "select"}
                                            </div>
                                            <i
                                                className="fa-solid fa-chevron-down"
                                                style={{ fontSize: 16 }}
                                            ></i>
                                        </div>
                                        {isDropdownOpen && (
                                            <div
                                                className="section-card custom-scrollbar dropdowm-container"
                                                style={{ borderRadius: 4 }}
                                            >
                                                {Object.entries(
                                                    groupedOptions
                                                ).map(
                                                    (
                                                        [ownerType, options],
                                                        index
                                                    ) => (
                                                        <div
                                                            key={index}
                                                            className="dropdown-section"
                                                        >
                                                            <h6 className="fw-bold">
                                                                {ownerType}
                                                            </h6>
                                                            <ul className="dropdown-options">
                                                                {options.map(
                                                                    (
                                                                        option,
                                                                        idx
                                                                    ) => (
                                                                        <li
                                                                            className={
                                                                                selectedReport &&
                                                                                selectedReport.value ===
                                                                                    option.value
                                                                                    ? "selected-option"
                                                                                    : ""
                                                                            }
                                                                            key={
                                                                                idx
                                                                            }
                                                                            onClick={() =>
                                                                                handleSelectChange(
                                                                                    option
                                                                                )
                                                                            }
                                                                        >
                                                                            {
                                                                                option.label
                                                                            }
                                                                        </li>
                                                                    )
                                                                )}
                                                            </ul>
                                                            {index !==
                                                                Object.keys(
                                                                    groupedOptions
                                                                ).length -
                                                                    1 && (
                                                                <Divider
                                                                    className="mb-0p"
                                                                    mx="mt-0p "
                                                                />
                                                            )}
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <ModifyViewButton
                                        table={table}
                                        selectedViewDataType={
                                            selectedViewDataType
                                        }
                                    />
                                </div>

                                <div className="secondary-report-header">
                                    <div className="data-view-button">
                                        <ReportButtons
                                            label="Save"
                                            iconClass="fa-solid fa-floppy-disk pl-8p"
                                            onClick={handleSaveButton}
                                            isDisabled={
                                                sortingColumnDetails &&
                                                (selectedFilterStatus === false
                                                    ? selectedFilterStatus
                                                    : columnOrderChange)
                                            }
                                        />
                                    </div>
                                    <div
                                        className="vertical-divider"
                                        style={{ margin: 0 }}
                                    ></div>
                                    <div className="data-view-button">
                                        <ReportButtons
                                            label="Reset"
                                            iconClass="fa-solid fa-arrow-rotate-left"
                                            onClick={handleResetButton}
                                            isDisabled={
                                                selectedFilterStatus === false
                                                    ? selectedFilterStatus
                                                    : columnOrderChange
                                            }
                                        />
                                    </div>
                                    <div
                                        className="vertical-divider"
                                        style={{ margin: 0 }}
                                    ></div>

                                    <div className="data-view-button w-auto px-2">
                                        <ReportButtons
                                            label="Manage my reports"
                                            onClick={handleManageReportsClick}
                                            iconClass="fa-solid fa-user-cog"
                                        />
                                    </div>
                                    {isFetching ? (
                                        <>
                                            <div
                                                className="vertical-divider"
                                                style={{ margin: 0 }}
                                            ></div>
                                            <div className="disabled-text">
                                                <span className="d-inline-block mx-4p fw-bold">
                                                    Loading
                                                    <i className="fa-solid fa-download pl-8p"></i>
                                                </span>
                                            </div>
                                        </>
                                    ) : (
                                        data.length > 0 && (
                                            <>
                                                <div
                                                    className="vertical-divider"
                                                    style={{ margin: 0 }}
                                                ></div>
                                                <div
                                                    onClick={handleExport}
                                                    className="export-to-excel"
                                                >
                                                    Export data
                                                </div>
                                            </>
                                        )
                                    )}
                                </div>
                            </div>
                            <p className="report-header pt-2">
                                {description}
                                {totalPages <= pagecounter && !isError && (
                                    <>
                                        <strong style={{ color: "#015294" }}>
                                            | Total records: {data.length}
                                        </strong>
                                        {largeDataSet && (
                                            <span style={{ float: "right", display: "flex", alignItems: "center", }}>
                                                <div style={{ position: "relative", display: "inline-block", }}>
                                                    <i
                                                        className="fa-solid fa-circle-info"
                                                        data-tip
                                                        data-for="infoTooltip"
                                                        style={{
                                                            marginRight: "8px",
                                                            color: "#037cb7",
                                                            cursor: "pointer",
                                                            fontSize: "16px",
                                                        }}
                                                        onMouseEnter={handleMouseEnter}
                                                        onMouseLeave={handleMouseLeave}
                                                    ></i>
                                                    { showTooltip && (
                                                        <div className="defense-message-tooltip">
                                                            Sorting and ordering is disabled due to the large volume of data present.
                                                            <div className="tooltipArrow"></div>
                                                        </div>
                                                    )}
                                                </div>
                                                <span
                                                style={{
                                                    color: "#037cb7",
                                                    fontSize: "14px",
                                                    fontStyle: "normal",
                                                    lineHeight: "16px",
                                                }}
                                                >
                                                    Data sorting/ordering is not active
                                                </span>
                                            </span> 
                                        )}
                                    </>
                                )}
                            </p>

                            {totalPages > pagecounter && (
                                <div className="total-table">
                                    <div className="spinner"></div>
                                    &nbsp;Retrieving {pagecounter *
                                        pagingCount}{" "}
                                    of {totalResults} records.
                                </div>
                            )}
                        </div>
                        {(isFetching || isDataFormattingComplete) ? (
                            <Loader />
                        ) : (
                            <div
                                className="custom-table-container"
                                style={{
                                    paddingBottom: data.length > 20 ? 0 : 16,
                                }}
                            >
                                <div
                                    className="custom-topscrollbar"
                                    ref={topScrollbarRef}
                                >
                                    {/* Render some content to visualize the scrollbar */}
                                    <div
                                        className="scrollbar-content"
                                        style={{
                                            width: getCenterTotalSize,
                                            height: "15px",
                                        }}
                                        ref={topScrollBarContentRef}
                                    ></div>
                                </div>
                                <div>
                                    {table.getState().columnFilters.length > 0 ? (
                                        <>
                                        {activeColumnWidth < 1180 && (
                                            <div className="horizontal-bar"></div>
                                        )}
                                        <div>
                                            <div className="filters-applied-area">
                                                Filters applied:{' '}
                                                {table.getState().columnFilters.map((filter) => (
                                                    <div className="filters-applied-box" key ={filter.id}>
                                                        <span className="pr-10p">{filter.id}: <strong>{filter.value}</strong></span>
                                                        <button className="filters-applied-clear-value"
                                                            onClick={() => table.getColumn(filter.id).setFilterValue('')}
                                                        >
                                                            <i class="fa-solid fa-x"></i>
                                                        </button>
                                                    </div>
                                                ))}

                                                <button className="filters-applied-clear-all"
                                                    onClick={() => table.resetColumnFilters()}
                                                >   
                                                    Clear All
                                                </button>
                                            </div>
                                            <div className="horizontal-bar"></div> 
                                        </div>
                                        </>
                                    ) : (<></>)}
                                </div>
                                <div
                                    className="custom-table-content "
                                    ref={tableContentRef}
                                >
                                    <table
                                        {...{
                                            style: {
                                                width: getCenterTotalSize,
                                            },
                                        }}
                                    >
                                        <thead id="reportingData">
                                            {table
                                                .getHeaderGroups()
                                                .map((headerGroup) => (
                                                    <tr key={headerGroup.id}>
                                                        {headerGroup.headers.map(
                                                            (header) => (
                                                                <DraggableColumnHeader
                                                                    key={
                                                                        header.id
                                                                    }
                                                                    header={
                                                                        header
                                                                    }
                                                                    table={
                                                                        table
                                                                    }
                                                                    activeFilterColumn={
                                                                        activeFilterColumn
                                                                    }
                                                                    toggleFilterPopup={
                                                                        toggleFilterPopup
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                    </tr>
                                                ))}
                                        </thead>
                                        {!isError && (
                                            <tbody>
                                                {table.getRowModel().rows.length === 0 && 
                                                data.length !== 0 &&
                                                table.getState().columnFilters.length > 0 ? (
                                                    <tr className="h-87">
                                                        <td colSpan={table.getAllColumns().length} style={{ textAlign: 'center'}}>
                                                            No data matches the current filter.
                                                        </td>
                                                    </tr>
                                                ) : (
                                                table
                                                    .getRowModel()
                                                    .rows.map((row, index) => (
                                                        <tr
                                                            className={
                                                                (index % 2 === 0
                                                                    ? "even "
                                                                    : "odd ") +
                                                                (index === 0
                                                                    ? "first "
                                                                    : "")
                                                            }
                                                            key={row.id}
                                                            style={{
                                                                height: table.getRowModel().rows.length === 1 && activeFilterColumn !== null ? '87px' : 
                                                                        table.getRowModel().rows.length === 2 ? '45px' : 'auto' 
                                                            }}
                                                        >
                                                            {row
                                                                .getVisibleCells()
                                                                .map((cell) => (
                                                                    <td
                                                                        key={
                                                                            cell.id
                                                                        }
                                                                        style={{
                                                                            width: cell.column.getSize(),
                                                                            textAlign:
                                                                                "left",
                                                                            wordBreak:
                                                                                "break-all",
                                                                        }}
                                                                        className="td"
                                                                    >
                                                                        {ssnValues[0] ===
                                                                        "No Access"
                                                                            ? flexRender(
                                                                                  cell
                                                                                      .column
                                                                                      .columnDef
                                                                                      .cell,
                                                                                  cell.getContext()
                                                                              )
                                                                            : getSSNDisplay(
                                                                                  cell,
                                                                                  index
                                                                              )}
                                                                    </td>
                                                                ))}
                                                        </tr>
                                                    ))
                                                )}
                                            </tbody>
                                        )}
                                    </table>
                                    {!isUninitialized &&
                                        !isFetching &&
                                        !isError &&
                                        claimData.length === 0 && (
                                            <div
                                                style={{
                                                    paddingLeft: 24,
                                                    paddingRight: 24,
                                                    paddingTop: 24,
                                                }}
                                            >
                                                <NoRecords
                                                    page="search"
                                                    label="search"
                                                    attribute1="search"
                                                    attribute3="search_error"
                                                />
                                            </div>
                                        )}
                                    {!isUninitialized && isError && (
                                        <div
                                            style={{
                                                paddingLeft: 24,
                                                paddingRight: 24,
                                                marginTop: -6,
                                                paddingBottom: 24,
                                            }}
                                        >
                                            <RequestFailed
                                                tabIndex={0}
                                                message={
                                                    "Your request failed, please try again or contact us for further assistance."
                                                }
                                                label="search"
                                                attribute1="employee search"
                                                attribute3="employee_error"
                                            />
                                        </div>
                                    )}
                                </div>
                                {data.length > 20 && activeColumnWidth > 1180 && (
                                    <div className="scrollbar-corner"></div>
                                )}
                            </div>
                        )}

                        {data.length > 20 && !isFetching && (
                            <div className="py-20p">
                                <Pagination
                                    pageCount={table.getPageCount()}
                                    gotoPage={(index) =>
                                        handlePageNumberClick(index + 1)
                                    }
                                    setPageSize={handleItemsPerPageChange}
                                    pageSize={itemsPerPage}
                                    options={[20, 50, 100]}
                                />
                            </div>
                        )}
                        {
                            <ReportSavingPage
                                onShow={show}
                                onDontSave={handleReportViewDontSave}
                                onSave={handleReportViewSave}
                                reportViewType={selectedViewDataType}
                            />
                        }
                        {showEditPage ? (
                            <ReportEditPage
                                onShow={show}
                                onDontSave={handleReportViewDontSave}
                                onSave={handleReportViewUpdate}
                                onSaveAs={handleReportViewSave}
                                notification={notificationStatus}
                                reportDetails={updateViewDetails}
                            />
                        ) : (
                            <ReportSavingPage
                                onShow={show}
                                onDontSave={handleReportViewDontSave}
                                onSave={handleReportViewSave}
                                notification={notificationStatus}
                                reportViewType={selectedViewDataType}
                            />
                        )}
                    </div>
                )}
            </div>{" "}
        </>
    );
}
export default ReportTable;
