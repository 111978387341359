import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
    absenceCoverage,
    capitalizeFirstLetter,
    CUSTOM_DATE,
    DATAVIEWTYPE,
    disabilityCoverage,
    disabilityStatus,
    getPresentDayFormat,
    getPreviousDays,
    getPreviousDaysEnd,
    getPreviousDaysStart,
    getPreviousDaysWithTimeFormat,
    getSelectedIsfOrganizationIds,
    INCLUDE_HISTORICAL_LEAVE,
    LAST_7_DAYS,
    leaveCoverage,
    lifeCoverage,
    paymentCoverage,
    paymentStatus,
    PERIOD_SCHEDULE_TYPE,
    PERIOD_STATUS,
    PREVIOUS_DAY,
    PREVIOUS_MONTH,
    PREVIOUS_QUARTER,
    PROTECTION_STATUS,
    STANDARD_REPORT_DATE_TIME,
    LAST_7_DAYS_LABEL,
    OWNER_TYPE,
    TODAY,
    PRIOR_BUSINESS_DAY,
    ONE_WEEK,
    getPreviousMonthWithTimeFormat,
    getDateTimeFormate,
} from "../../utils/helpers";
import ReportViewFilter from "./ReportViewFilter";
import ReportViewData from "./ReportViewData";
import {
    useSaveReportViewMutation,
    useLazyGetReportDetailsQuery,
    useUpdateReportDetailsMutation,
    useUpdateFrequentlyUsedReportsMutation,
    useLazyGetChangesReportQuery,
} from "../../slices/saveReportViewSlice";
import {
    useLazyGetClaimsSearchQuery,
    useLazyGetClaimsPaymentSearchQuery,
    useLazyGetLeavesSearchQuery,
} from "../../slices/dataviewApiSlice";
import ReportSaveNotification from "./ReportSaveNotification";
import ReportSaveErrorNotification from "./ReportSaveErrorNotification";
import FooterNote from "../claimleaveview/FooterNote";
import moment from "moment";
import GTM from "../../GTM";
import AbsenceSearch from "../../models/AbsenceSearch";
import { clearDlUrlValues } from "../../slices/authSlice";
import {
    absenceType,
    changesReportType,
    disabilityType,
    leaveType,
    lifeType,
    paymentHistoryType,
} from "../../constants";
import { useFlags } from "launchdarkly-react-client-sdk";

function ReportViewPage(props) {
    const { user } = useSelector((state) => state.auth);
    const { config } = useSelector((state) => state.app);
    let previousUrls = props.previousUrl;
    let page_referrer = previousUrls[previousUrls.length - 1];
    let mmDateFormat = "MM/DD/YYYY";
    const gtm = new GTM(null);
    const { viewtype } = useParams();
    let reportViewType = capitalizeFirstLetter(viewtype);

    // Function to determine page_referrer_title based on page_referrer_url
    const getPageReferrerTitle = (page_referrer_url) => {
        return page_referrer_url?.includes("/reportingcapabilities")
            ? "run reports"
            : undefined;
    };
    const { allPagingCounts } = useFlags();
    const { changes: changePagingCount, claim: claimPagingCount, leave: leavePagingCount, payments: paymentPagingCount } = allPagingCounts || {};
    const page_referrer_title = getPageReferrerTitle(page_referrer);
    const [totalPages, setTotalPages] = useState(null);
    const [claimsTotalpages, setClaimsTotalPages] = useState(null);
    const [leavesTotalpages,setLeavesTotalPages] = useState(null);
    const [isDataFormattingComplete,setIsDataFormattingComplete]=useState(true)
    useEffect(() => {
        const userName = "";
        const userId = user ? user.userId : "";
        const userRole = user.isUserInternal ? "internal" : "external";

        gtm.PageView(
            `reports ${reportViewType}`,
            userName,
            userId,
            userRole,
            window.location.href,
            `${config.REACT_APP_BASE_URL}${page_referrer}`,
            page_referrer_title,
            ""
        );
    }, [20000]);
    // api call to get report data
    
    const [reportTableData, setReportTableData] = useState([]);
    
    const [
        getClaimsSearch,
        { data: reportClaimsTableData = [], isFetching, isUninitialized, isError },
    ] = useLazyGetClaimsSearchQuery();

    const [
        getClaimsSearchPaging,
        { data: reportClaimsTableDataPage = [], isFetching: isFetchingClaimsPaging , isUninitialized: isUninitializedClaimsPaging, isError: isErrorClaimsPaging },
    ] = useLazyGetClaimsSearchQuery();

    const [pagecounter, setPageCounter] = useState(1);
    const [absenceTotalpages, setAbsenceTotalPages] = useState(null);

    useEffect(() => {
        if(isFetching){
            setIsDataFormattingComplete(true)
        }
        if (!isUninitialized && !isFetching && reportClaimsTableData?.claimsSearch) {
            setReportTableData([]);
            setClaimsTotalPages(null);
            if (reportViewType === disabilityType && reportClaimsTableData.claimsSearch) {
                setTotalResults(reportClaimsTableData?.searchResultMetadata?.totalResults);
                setReportTableData(prevData => [...prevData, ...reportClaimsTableData.claimsSearch]);
                setPageCounter(1);
                setTotalPages(Math.ceil(reportClaimsTableData?.searchResultMetadata?.totalResults / claimPagingCount));
                setIsDataFormattingComplete(false)
                
            } else if (reportViewType === absenceType && reportClaimsTableData.claimsSearch) {
                const totalPages = Math.ceil(reportClaimsTableData?.searchResultMetadata?.totalResults / claimPagingCount);
                setClaimsTotalPages(totalPages === 0 ? null : totalPages);
                setIsDataFormattingComplete(false)
            }
        }
    }, [reportClaimsTableData, isFetching, isUninitialized, reportViewType]);

    
    
    useEffect(() => {
        if (!isUninitializedClaimsPaging && !isFetchingClaimsPaging && reportClaimsTableDataPage?.claimsSearch) {
            if (reportViewType === disabilityType && reportClaimsTableDataPage.claimsSearch) {
                setReportTableData(prevData => [...prevData, ...reportClaimsTableDataPage.claimsSearch]);
            }
        }
    }, [reportClaimsTableDataPage, isFetchingClaimsPaging, isUninitializedClaimsPaging, reportViewType]);

    let reportTableDataValue;

    if (reportViewType === disabilityType) {
        reportTableDataValue = reportTableData;
    } else {
        reportTableDataValue = "";
    }

    const loadPagingData = async (counter) => {
        let reportingPeriodStartDate =  getPresentDayFormat(
                customDateRange[0].startDate
            );
        let reportingPeriodEndDate =  getPresentDayFormat(
                customDateRange[0].endDate
            );
        if (reportingPeriodValue.value === PREVIOUS_DAY) {
            reportingPeriodStartDate = getPreviousDays(reportLastFetched, 1);
            reportingPeriodEndDate = getPresentDayFormat(reportLastFetched);
        } else if (reportingPeriodValue.value === LAST_7_DAYS) {
            reportingPeriodStartDate = getPreviousDays(reportLastFetched, 7);
            reportingPeriodEndDate = getPreviousDays(reportLastFetched, 1);
        } else if (reportingPeriodValue.value === PREVIOUS_MONTH) {
            reportingPeriodStartDate = getPreviousDaysStart(
                reportLastFetched,
                "month"
            );
            reportingPeriodEndDate = getPreviousDaysEnd(
                reportLastFetched,
                "month"
            );
        } else if (reportingPeriodValue.value === PREVIOUS_QUARTER) {
            reportingPeriodStartDate = getPreviousDaysStart(
                reportLastFetched,
                "quarter"
            );
            reportingPeriodEndDate = getPreviousDaysEnd(
                reportLastFetched,
                "quarter"
            );
        }
         let Intialfilters = {
            organizationId: selectedIsfOrganizationIds,
            claimCoverageTypes: selectedCoverageType.map((item) => item.value),
            claimStatus: getMappedValues(
                reportingStatusValue.map((item) => item.value)
            ),
            effectiveStartDate: reportingPeriodStartDate,
            effectiveEndDate: reportingPeriodEndDate,
            groupAgreementDivision:
                selectedPolicyDivision.length > 500
                    ? ""
                    : selectedPolicyDivision,

            startIndex: 0,
            startPage: counter,
            count: claimPagingCount,
        };
        let reportStatus = "";
        if (selectedViewData[0].Status === "All") {
            reportStatus = getMappedValues(  reportingStatusValue.map((item) => item.value));
        }
        let intialPaymentfilters = {
            organizationId: selectedIsfOrganizationIds,
            claimCoverageTypes:  selectedCoverageType.map((item) => item.value),
            effectiveStartDate: reportingPeriodStartDate,
            effectiveEndDate: reportingPeriodEndDate,
            paymentStatus: reportStatus,
            startIndex: 0,
            startPage: counter,
            count: paymentPagingCount,
        }
        let intialLeavefilters = {
            organizationId: selectedIsfOrganizationIds,
            coverageType: coverageType,
            effectiveStartDate: reportingPeriodStartDate,
            effectiveEndDate: reportingPeriodEndDate,
            leaveScheduleType: periodScheduleTypeValue.map(
                (item) => item.value
            ),
            historicalLeaveInd:
                includeHistoricalLeaveValue.value === "No"
                    ? "N"
                    : "Y",
            leavePeriodStatus: periodStatusValue.map(
                (item) => item.value
            ),
            leaveStatus: reportingStatusValue.map(
                (item) => item.value
            ),
            protectionStatus: mapProtectionStatus(
                protectionStatusValue
            ),
            startIndex: 0,
            startPage: counter,
            count: leavePagingCount,
        };
        if (reportViewType === disabilityType) {
            getClaimsSearchPaging({
                filters: Intialfilters,
            });
        } else if (reportViewType === paymentHistoryType) {
            getClaimsPaymentSearchPaging({
                filters: intialPaymentfilters,
            });
        } else if (reportViewType === leaveType) {
            getLeavesSearchPaging({
                filters: intialLeavefilters,
            });
        } else if (reportViewType === changesReportType) {
            getChangesReportPaging({
                startDate: reportingPeriodStartDate,
                isfOrganizationIds: selectedIsfOrganizationIds,
                startPage: counter,
                count: changePagingCount,
            });
        } else if (reportViewType === absenceType) {
            intialLeavefilters.coverageType = "Leave";
            getClaimsSearchPaging({
                filters: Intialfilters,
            });
            getLeavesSearchPaging({
                filters: intialLeavefilters,
            });
        }
    };

    const [
        getChangesReport,
        {
            data: leaveChanges = [],
            isFetching: isFetchingLeaveChages,
            isUninitialized: isUninitializedLeaveChanges,
            isError: isErrorLeaveChanges,
        },
    ] = useLazyGetChangesReportQuery();

    const [
        getChangesReportPaging,
        {
            data: leaveChangesPage = [],
            isFetching: isFetchingLeaveChangesPaging,
            isUninitialized: isUninitializedLeaveChangesPaging,
            isError: isErrorLeaveChangesPaging,
        }
    ] = useLazyGetChangesReportQuery();

    useEffect(() => {
        if(isFetchingLeaveChages){
            setIsDataFormattingComplete(true)
        }
    if (!isUninitializedLeaveChanges && !isFetchingLeaveChages && leaveChanges?.data) {
        setReportTableData([]);
        setTotalResults(leaveChanges?.headers?.totalResults);
        if (reportViewType === changesReportType && leaveChanges?.data) {
            setReportTableData(prevData => [...prevData, ...leaveChanges.data]);
            setPageCounter(1);
            setTotalPages(Math.ceil(leaveChanges?.headers?.totalResults / changePagingCount));
            setIsDataFormattingComplete(false)
        }
    }
    }, [leaveChanges, isFetchingLeaveChages, isUninitializedLeaveChanges, reportViewType]);

    useEffect(() => {
        if (!isUninitializedLeaveChangesPaging && !isFetchingLeaveChangesPaging && leaveChangesPage?.data) {
            if (reportViewType === changesReportType && leaveChangesPage?.data.length > 0) {
                setReportTableData(prevData => {
                    const updatedData = [...prevData, ...leaveChangesPage.data];
                    return updatedData;
                });
            }
        }
    }, [leaveChangesPage, isFetchingLeaveChangesPaging, isUninitializedLeaveChangesPaging, reportViewType]);
    

    const [
        getClaimsPaymentSearch,
        {
            data: reportPaymentTableData = [],
            isFetching: isFetchingPayment,
            isUninitialized: isUninitializedPayment,
            isError: isErrorPayment,
        },
    ] = useLazyGetClaimsPaymentSearchQuery();

    const [
        getClaimsPaymentSearchPaging,
        { data: reportPaymentTableDataPage = [],
            isFetching: isFetchingPaymentPaging,
            isUninitialized: isUninitializedPaymentPaging,
            isError: isErrorPaymentPaging 
        },
    ] = useLazyGetClaimsPaymentSearchQuery();

    useEffect(() => {
        if(isFetchingPayment){
            setIsDataFormattingComplete(true)
        }
        if (!isUninitializedPayment && !isFetchingPayment && reportPaymentTableData?.claimsPaymentSearch) {
            setReportTableData([]);
            setTotalResults(reportPaymentTableData?.searchResultMetadata?.totalResults);
            if (reportViewType === paymentHistoryType && reportPaymentTableData.claimsPaymentSearch) {
                setReportTableData(reportPaymentTableData.claimsPaymentSearch);
                setPageCounter(1);
                setIsDataFormattingComplete(false)
                setTotalPages(Math.ceil(reportPaymentTableData?.searchResultMetadata?.totalResults / paymentPagingCount));
            }
        }
    }, [reportPaymentTableData, isFetchingPayment, isUninitializedPayment, reportViewType]);

    useEffect(() => {
        if (!isUninitializedPaymentPaging && !isFetchingPaymentPaging && reportPaymentTableDataPage?.claimsPaymentSearch) {
            if (reportViewType === paymentHistoryType && reportPaymentTableDataPage.claimsPaymentSearch) {
                setReportTableData(prevData => [...prevData, ...reportPaymentTableDataPage.claimsPaymentSearch]);
            }
        }
    }, [reportPaymentTableDataPage, isFetchingPaymentPaging, isUninitializedPaymentPaging, reportViewType]);

    const [
        getLeavesSearch,
        {
            data: reportLeaveTableData = [],
            isFetching: isFetchingLeave,
            isUninitialized: isUninitializedLeave,
            isError: isErrorLeave,
        },
    ] = useLazyGetLeavesSearchQuery();

    const [
        getLeavesSearchPaging,
        {
            data: reportLeaveTableDataPaging = [],
            isFetching: isFetchingLeavePaging,
            isUninitialized: isUninitializedLeavePaging,
            isError: isErrorLeavePaging,
        },
    ] = useLazyGetLeavesSearchQuery();

    useEffect(() => {
        if(isFetchingLeave){
            setIsDataFormattingComplete(true)
        }
        if (!isUninitializedLeave && !isFetchingLeave && reportLeaveTableData?.LeaveSearch) {
            setReportTableData([]);
            setLeavesTotalPages(null);
            if (reportViewType === leaveType && reportLeaveTableData.LeaveSearch) {
                setTotalResults(reportLeaveTableData?.searchResultMetadata?.totalResults);
                const getFilteredData=reportLeaveTableData.LeaveSearch.filter((item)=>{
                    if(protectionStatusValue.length && protectionStatusValue.length!==PROTECTION_STATUS.length && periodStatusValue.length===PERIOD_STATUS.length){
                        return protectionStatusValue.map((items)=>items.label).includes(item.protectionStatusDesc) 
                    }
                    if(periodStatusValue.length && periodStatusValue.length!==PERIOD_STATUS.length && protectionStatusValue.length===PROTECTION_STATUS.length){
                        return periodStatusValue.map((items)=>items.label).includes(item.periodStatus)
                    }
                    if(protectionStatusValue.length &&  protectionStatusValue.length !==PROTECTION_STATUS.length && periodStatusValue.length && periodStatusValue.length!==PERIOD_STATUS.length){                        
                        return protectionStatusValue.map((items)=>items.label).includes(item.protectionStatusDesc)  && periodStatusValue.map((items)=>items.label).includes(item.periodStatus)
                    }
                    return reportLeaveTableData.LeaveSearch
                })
                setReportTableData(prevData => [...prevData, ...getFilteredData]);
                setPageCounter(1);
                setIsDataFormattingComplete(false)
                setTotalPages(Math.ceil(reportLeaveTableData?.searchResultMetadata?.totalResults / leavePagingCount));
            } else if (reportViewType === absenceType && reportLeaveTableData.LeaveSearch) {
                const totalPages = Math.ceil(reportLeaveTableData?.searchResultMetadata?.totalResults / leavePagingCount);
                setLeavesTotalPages(totalPages === 0 ? null : totalPages);
                setIsDataFormattingComplete(false)
            }
        }
    }, [reportLeaveTableData, isFetchingLeave, isUninitializedLeave, reportViewType]);

    useEffect(() => {
        if (!isUninitializedLeavePaging && !isFetchingLeavePaging && reportLeaveTableDataPaging?.LeaveSearch) {
            if (reportViewType === leaveType && reportLeaveTableDataPaging.LeaveSearch) {
                setReportTableData(prevData => [...prevData, ...reportLeaveTableDataPaging.LeaveSearch]);
            }
        }
    }, [reportLeaveTableDataPaging, isFetchingLeavePaging, isUninitializedLeavePaging, reportViewType]);

    
    useEffect(() => {
        if (claimsTotalpages !== null && leavesTotalpages!== null && reportViewType === absenceType) {
            setAbsenceTotalPages(claimsTotalpages + leavesTotalpages);
            setTotalResults(reportLeaveTableData?.searchResultMetadata?.totalResults + reportClaimsTableData?.searchResultMetadata?.totalResults);
        }
    }, [claimsTotalpages, leavesTotalpages]);

    const {
        selectedReport,
        selectedReportTypeData: reportData,
        title,
        portalUserData,
        isError: portalUserApiError,
    } = useLocation().state || {};
    const [saveReportViewMutation, { data: savedReportViewId }] =
        useSaveReportViewMutation();

    const [updateFrequentlyUsedReportsViewId] =
        useUpdateFrequentlyUsedReportsMutation();
    const [getReportDetails, { data: savedReportData }] =
        useLazyGetReportDetailsQuery({});
    const [selectedFilters, setSelectedFilters] = useState();
    const [reportingPeriodValue, setReportingPeriodValue] = useState({
        value: LAST_7_DAYS,
        label: LAST_7_DAYS_LABEL,
    });
    const [absenceViewData, setAbsenceViewData] = useState([]);
    const [includeHistoricalLeaveValue, setIncludeHistoricalLeaveValue] =
        useState(INCLUDE_HISTORICAL_LEAVE[1]);
    const [periodScheduleTypeValue, setPeriodScheduleTypeValue] =
        useState(PERIOD_SCHEDULE_TYPE);
    const [periodStatusValue, setPeriodStatusValue] = useState(PERIOD_STATUS);
    const [protectionStatusValue, setProtectionStatusValue] =
        useState(PROTECTION_STATUS);
    const childRef = useRef(null);
    const [disclaimer, setDisclaimer] = useState("");
    const [customDateRange, setCustomDateRange] = useState([
        {
            startDate: null,
            endDate: null,
            key: "selection",
            label: "Custom Range",
            selectedFromDate: null,
            selectedEndDate: null,
        },
    ]);
    const [columnFilters, setColumnFilters] = useState([]);
       
    const [selectionMode, setSelectionMode] = useState("start"); // Track if we are selecting start or end date
    const [totalResults, setTotalResults] = useState([]);
    const getCamelCasedReportViewData = (data) => {
        return data.map((obj1) => ({
            DataViewType: obj1.DataViewType,
            Description: obj1.Description,
            MostRecentOrder: obj1.MostRecentOrder,
            MostUsedOrder: obj1.MostUsedOrder,
            Name: obj1.Name,
            OwnerType: obj1.OwnerType,
            CoverageType: obj1.CoverageType,
            ViewId: obj1.ViewId,
            Status: obj1.Status,
            EffectiveStartDate: obj1.EffectiveStartDate,
            EffectiveEndDate: obj1.EffectiveEndDate,
            ColumnInformation: obj1.ColumnInformation.map((obj) => ({
                accessorKey: obj.AccessorKey,
                checked: obj.Checked,
                enableHiding: obj.LockedDisplay,
                header: obj.Header,
                id: obj.Id,
                cell: (info) => info.getValue(),
                size: obj.Size,
                minSize: obj.MinSize,
                maxSize: obj.MaxSize,
            })),
            PeriodScheduleTypes: obj1.PeriodScheduleTypes,
            IncludeHistoricalLeave: obj1.IncludeHistoricalLeave,
            PeriodStatuses: obj1.PeriodStatuses,
            ProtectionStatuses: obj1.ProtectionStatuses,
            GroupAgreementDivisions: obj1.GroupAgreementDivisions,
            SortOrder: obj1.SortOrder,
        }));
    };
    const modifiedObjectFields = getCamelCasedReportViewData(reportData);
    let organizationIDISF = "";

    let allowedPoliciesAndDivisions = {};
    let allowedReportingGroups = [];

    const [selectedViewData, setSelectedViewData] =
        useState(modifiedObjectFields);
    const {
        selectedOrganizationsReports: userSelectedOrganizationReports,
        selectedOrganizationsIdReports: reportId,
        selectedRelatedOrganizationsForReports: selectedRelatedOrganizations,
    } = useSelector((state) => state.reportOrganization);

    // get ssn access for selected organization
    const { organizationsAccess } = useSelector((state) => state.organizations);

    const [reportSaving, setReportSaving] = useState(false);
    const [loaderStatus, setLoaderStatus] = useState(false);
    const [selectedFilterStatus, setSelectedFilterStatus] = useState(true);
    const [allViewsData, setAllViewsData] = useState(modifiedObjectFields);
    const [updateReportViewMutation] = useUpdateReportDetailsMutation();
    const [showReportSaveNotification, setShowReportSaveNotification] =
        useState(false);
    const [
        showReportSaveErrorNotification,
        setShowReportSaveErrorNotification,
    ] = useState(false);
    const [notificationMessageIndicator, setNotificationMessageIndicator] =
        useState();
    const [reportLastFetched, setreportLastFetched] = useState();
    const [coverageType, setCoverageType] = useState();
    const [reportUpdated, setReportUpdated] = useState(false);
    const [enableApply, setEnableApply] = useState(false);
    const [enableReset, setEnableReset] = useState(false);
    const [disableConfirm, setDisableConfirm] = useState(false);
    const coverageTypeOptions = (
        (typeof coverageType === "string"
            ? coverageType.split(",")
            : coverageType) || []
    )?.map((code) => ({
        value: code,
        label: code,
    }));
    const status =
        reportViewType === paymentHistoryType
            ? paymentStatus
            : disabilityStatus;
    let coverage;

    if (reportViewType === paymentHistoryType) {
        coverage = paymentCoverage;
    } else if (reportViewType === disabilityType) {
        coverage = disabilityCoverage;
    } else if (
        reportViewType === absenceType ||
        reportViewType === changesReportType
    ) {
        coverage = absenceCoverage;
    } else if (reportViewType === lifeType) {
        coverage = lifeCoverage;
    } else if (reportViewType === leaveType) {
        coverage = leaveCoverage;
    } else if (reportViewType === changesReportType) {
        coverage = absenceCoverage;
    }
    if (
        portalUserData &&
        portalUserData.assignedOrgs &&
        Array.isArray(portalUserData.assignedOrgs)
    ) {
        const fullListOfPoliciesAndDivisions = {};

        portalUserData.assignedOrgs.forEach((assignedOrg) => {
            assignedOrg.assignedPolicies.forEach((policy) => {
                const {
                    policyId,
                    divisionId,
                    divisionName,
                    reportingGroupInd,
                } = policy;

                if (reportingGroupInd === "N" || user.isUserInternal) {
                    if (!fullListOfPoliciesAndDivisions[policyId]) {
                        fullListOfPoliciesAndDivisions[policyId] = {
                            policyId,
                            divisions: [],
                        };
                    }

                    fullListOfPoliciesAndDivisions[policyId].divisions.push({
                        divisionId,
                        divisionName,
                    });
                }

                if (reportingGroupInd === "Y" || user.isUserInternal) {
                    allowedReportingGroups.push({
                        value: policyId + "." + divisionId,
                        label: String(+divisionId),
                    });
                }
            });
        });

        allowedPoliciesAndDivisions = Object.values(
            fullListOfPoliciesAndDivisions
        );

        allowedReportingGroups = allowedReportingGroups.sort((a, b) => {
            return a.label - b.label;
        });
    } else {
        allowedPoliciesAndDivisions = [];
    }

    const initialPolicyDivision = allowedPoliciesAndDivisions.flatMap(
        (item) => [
            item.policyId,
            ...(Array.isArray(item.divisions)
                ? item.divisions.map(
                      (division) => `${item.policyId}-${division.divisionId}`
                  )
                : []),
        ]
    );

    // filter values
    const [selectedCoverageType, setSelectedCoverageType] = useState(coverage);
    const [reportingStatusValue, setReportingStatusValue] = useState(status);
    const [selectedPolicyDivision, setSelectedPolicyDivision] = useState(
        initialPolicyDivision
    );
    const [selectedReportingGroup, setSelectedReportingGroup] = useState(
        allowedReportingGroups
    );

    const handlePolicyDivision = (policy) => {
        setSelectedPolicyDivision(policy);
        setEnableReset(true);
        setEnableApply(true);
    };
    const handleReportingGroup = (reportingGroup) => {
        setSelectedReportingGroup(reportingGroup);
        setEnableReset(true);
        setEnableApply(true);
    };

    const {
        selectedIsfOrganizationIds,
        organizationGroupId,
        relatedOrganizationNames,
        organizationName,
    } = getSelectedIsfOrganizationIds(
        userSelectedOrganizationReports,
        reportId,
        selectedRelatedOrganizations
    );

    const handleViewOptionChange = (item) => {
        setEnableReset(false);
        setEnableApply(false);
        let findSelectedViewDetails = "";
        if (savedReportData) {
            findSelectedViewDetails =
                getCamelCasedReportViewData(savedReportData);
            setSelectedViewData([
                findSelectedViewDetails.find((obj) => obj.Name === item.value),
            ]);
            const getSelectedView = findSelectedViewDetails.find(
                (obj) => obj.Name === item.value
            );
            setCustomDateRange([
                {
                    startDate: moment(
                        getSelectedView.EffectiveStartDate.substring(0, 10)
                    ).format(mmDateFormat),
                    endDate: moment(
                        getSelectedView.EffectiveEndDate.substring(0, 10)
                    ).format(mmDateFormat),
                    key: "selection",
                    selectedFromDate: moment(
                        getSelectedView.EffectiveStartDate.substring(0, 10)
                    ).format(mmDateFormat),
                    selectedEndDate: moment(
                        getSelectedView.EffectiveEndDate.substring(0, 10)
                    ).format(mmDateFormat),
                },
            ]);
            setDisableConfirm(true);
            const selectedReportTypeData = savedReportData
                .filter((obj) =>
                    obj.DataViewType.includes(reportViewType.slice(0, 4))
                )
                .map((obj) => obj);
            setAllViewsData(
                getCamelCasedReportViewData(selectedReportTypeData)
            );
        } else {
            findSelectedViewDetails = modifiedObjectFields.find(
                (obj) => obj.Name === item.value
            );
            setSelectedViewData([findSelectedViewDetails]);
            let startDate = findSelectedViewDetails.EffectiveStartDate;
            let endDate = findSelectedViewDetails.EffectiveEndDate;
            if (
                startDate === STANDARD_REPORT_DATE_TIME ||
                endDate === STANDARD_REPORT_DATE_TIME
            ) {
                handleDateRangeChange({
                    reset: true,
                });
            } else {
                setCustomDateRange([
                    {
                        startDate: startDate.substring(0, 10),
                        endDate: endDate.substring(0, 10),
                        key: "selection",
                        selectedFromDate: startDate.substring(0, 10),
                        selectedEndDate: endDate.substring(0, 10),
                    },
                ]);
                setDisableConfirm(true);
            }
        }
    };

    useEffect(() => {
        if (reportUpdated) {
            const selectedReportTypeData = savedReportData
                .filter((obj) =>
                    obj.DataViewType.includes(reportViewType.slice(0, 4))
                )
                .map((obj) => obj);
            const updatedSelectedView = selectedReportTypeData.find(
                (obj) => obj.ViewId === selectedViewData[0].ViewId
            );
            const modifiedSelectedViewData = {
                DataViewType: updatedSelectedView.DataViewType,
                Description: updatedSelectedView.Description,
                MostRecentOrder: updatedSelectedView.MostRecentOrder,
                MostUsedOrder: updatedSelectedView.MostUsedOrder,
                Name: updatedSelectedView.Name,
                OwnerType: updatedSelectedView.OwnerType,
                CoverageType: updatedSelectedView.CoverageType,
                ViewId: updatedSelectedView.ViewId,
                Status: updatedSelectedView.Status,
                EffectiveStartDate: updatedSelectedView.EffectiveStartDate,
                EffectiveEndDate: updatedSelectedView.EffectiveEndDate,
                SortOrder: updatedSelectedView.SortOrder,
                ColumnInformation: updatedSelectedView.ColumnInformation.map(
                    (obj) => ({
                        accessorKey: obj.AccessorKey,
                        checked: obj.Checked,
                        enableHiding: obj.LockedDisplay,
                        header: obj.Header,
                        id: obj.Id,
                        cell: (info) => info.getValue(),
                        size: obj.Size,
                        minSize: obj.MinSize,
                        maxSize: obj.MaxSize,
                    })
                ),
                PeriodScheduleTypes: updatedSelectedView.PeriodScheduleTypes,
                IncludeHistoricalLeave:
                    updatedSelectedView.IncludeHistoricalLeave,
            };
            setSelectedViewData([modifiedSelectedViewData]);
            setAllViewsData(
                getCamelCasedReportViewData(selectedReportTypeData)
            );
        }
    }, [reportUpdated, reportViewType, savedReportData]);

    useEffect(() => {
        if (reportSaving === true) {
            setReportSaving(false);
            if (savedReportData.length) {
                const modifiedObjectFields =
                    getCamelCasedReportViewData(savedReportData);
                const selectedReportTypeData = modifiedObjectFields
                    .filter((obj) =>
                        obj.DataViewType.includes(reportViewType.slice(0, 4))
                    )
                    .map((obj) => obj);
                setAllViewsData(selectedReportTypeData);
                setSelectedViewData([
                    selectedReportTypeData.find(
                        (obj) => obj.ViewId === savedReportViewId.ViewId
                    ),
                ]);
            }
        }
    }, [reportSaving]);

    useEffect(() => {
        let findSelectedViewDetails = "";
        if (selectedReport && selectedReport !== undefined) {
            findSelectedViewDetails = modifiedObjectFields.find(
                (obj) => obj.ViewId === selectedReport.ViewId
            );
            setSelectedViewData([findSelectedViewDetails]);
        }
    }, [selectedReport]);

    const reportDetailsParams = {
        isfUserId: user.userId,
        dataViewType: DATAVIEWTYPE,
        selectedIsfOrganizationIds: selectedIsfOrganizationIds,
    };

    // Include organizationGroupId if it's defined
    if (organizationGroupId) {
        reportDetailsParams.organizationGroupId = organizationGroupId;
    }
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(clearDlUrlValues());
    }, []);

    //setting policy and division toe save report
    const filteredArray = selectedPolicyDivision.filter((value) =>
        value.includes("-")
    );

    const GroupAgreementDivisions = filteredArray.map((value) => {
        return {
            GroupAgreementDivision: value,
            DisplayValue: value,
        };
    });

    const handleViewReportSave = (
        changedColumnSorting,
        changedColumnOrder,
        viewName,
        viewDescription
    ) => {
        setLoaderStatus(true);
        if (reportViewType === "PaymentHistory") {
            reportViewType = "Payments";
        }
        saveReportViewMutation({
            dataToUpdate: {
                IsfUserId: user.userId,
                OwnerType: "Personal",
                SelectedIsfOrganizationIds: selectedIsfOrganizationIds,
                DataViewType: reportViewType,

                DataViewInfo: {
                    Name: viewName,
                    Description: viewDescription,
                    Parameters: {
                        CoverageType: selectedCoverageType.map(
                            (item) => item.value
                        ),
                        EffectiveEndDt: reportingPeriodValue
                            ?getDateTimeFormate(reportingPeriodValue.label.split("-")[1].slice(-11))
                            :viewtype === "PaymentHistory"
                            ?getPreviousMonthWithTimeFormat(moment(getPreviousDaysEnd(reportLastFetched, "month")).format(
                                mmDateFormat
                            ) )                            
                            :getPreviousDaysWithTimeFormat(
                                  reportLastFetched,
                                  1
                              ),
                        EffectiveStartDt: reportingPeriodValue
                            ? getDateTimeFormate(reportingPeriodValue.label.split("-")[0].slice(-11).replace(" ", ""))
                            :viewtype === "PaymentHistory"?
                            getPreviousMonthWithTimeFormat(moment(getPreviousDaysStart(reportLastFetched, "month")).format(
                                mmDateFormat
                            ) )
                            :getPreviousDaysWithTimeFormat(
                                reportLastFetched,
                                7
                            ),
                        GroupAgreementDivisions: GroupAgreementDivisions,
                        IncludeHistoricalLeave:
                            includeHistoricalLeaveValue.value === "No"
                                ? false
                                : true,
                        PaymentStatuses: null,
                        PeriodScheduleTypes: periodScheduleTypeValue?.map(
                            (item) => item.value
                        ),
                        PeriodStatuses: periodStatusValue?.map(
                            (item) => item.value
                        ),
                        ProductGroupCds: ["STD", "SI-STD"],
                        ProtectionStatuses: protectionStatusValue?.map(
                            (item) => item.value
                        ),
                        ReportingGroups: null,
                        ReportingPeriodMethod: null,
                        Status: reportingStatusValue
                            ?.map((item) => item.value)
                            .toString(),
                    },
                    TableDisplayInformation: {
                        SortOrder:
                            changedColumnSorting[0] === undefined
                                ? null
                                : changedColumnSorting[0],
                        ColumnInformation: changedColumnOrder,
                    },
                },
            },
        })
            .unwrap()
            .then(() => {
                getReportDetails({
                    isfUserId: user.userId,
                    selectedIsfOrganizationIds: selectedIsfOrganizationIds,
                    dataViewType: DATAVIEWTYPE,
                }).then(() => {
                    setReportSaving(true);
                    setLoaderStatus(false);
                    setSelectedFilterStatus(true);
                    setShowReportSaveNotification(true);
                    setTimeout(() => {
                        setShowReportSaveNotification(false);
                    }, 6000);
                });
            })
            .catch((e) => {
                setLoaderStatus(false);
                setShowReportSaveErrorNotification(true);
                setTimeout(() => {
                    setShowReportSaveErrorNotification(false);
                }, 6000);
            });
    };

    const handleViewReportUpdate = (
        changedColumnSorting,
        changedColumnOrder,
        updateViewDetails,
        viewName,
        viewDescription
    ) => {
        setLoaderStatus(true);
        updateReportViewMutation({
            dataToUpdate: {
                IsfUserId: user.userId,
                OwnerType: "Personal",
                SelectedIsfOrganizationIds: selectedIsfOrganizationIds,
                DataViewType: updateViewDetails.DataViewType,
                IsEnabled: true,
                DataViewInfo: {
                    Name: viewName,
                    Description: viewDescription,
                    Parameters: {
                        CoverageType: selectedCoverageType.map(
                            (item) => item.value
                        ),
                        EffectiveEndDt: selectedFilters?.effectiveEndDate
                            ? getDateTimeFormate(reportingPeriodValue.label.split("-")[1].slice(-11))
                            :viewtype === "PaymentHistory"
                            ?getPreviousMonthWithTimeFormat(moment(getPreviousDaysEnd(reportLastFetched, "month")).format(
                                mmDateFormat
                            ) )                            
                            :getPreviousDaysWithTimeFormat(
                                  reportLastFetched,
                                  1
                              ),
                        EffectiveStartDt: selectedFilters?.effectiveStartDate
                            ?  getDateTimeFormate(reportingPeriodValue.label.split("-")[0].slice(-11).replace(" ", ""))
                            :viewtype === "PaymentHistory"?
                            getPreviousMonthWithTimeFormat(moment(getPreviousDaysStart(reportLastFetched, "month")).format(
                                mmDateFormat
                            ) )
                            :getPreviousDaysWithTimeFormat(
                                reportLastFetched,
                                7
                            ),
                        GroupAgreementDivisions: GroupAgreementDivisions,
                        IncludeHistoricalLeave: false,
                        PaymentStatuses: null,
                        ProductGroupCds: ["STD", "SI-STD"],
                        PeriodScheduleTypes: periodScheduleTypeValue?.map(
                            (item) => item.value
                        ),
                        PeriodStatuses: periodStatusValue?.map(
                            (item) => item.value
                        ),
                        ProtectionStatuses: protectionStatusValue?.map(
                            (item) => item.value
                        ),
                        ReportingGroups: null,
                        ReportingPeriodMethod: null,
                        Status: reportingStatusValue
                            .map((item) => item.value)
                            .toString(),
                    },
                    TableDisplayInformation: {
                        SortOrder:
                            changedColumnSorting[0] === undefined
                                ? null
                                : changedColumnSorting[0],
                        ColumnInformation: changedColumnOrder,
                    },
                },
            },
            viewId: updateViewDetails.ViewId,
        })
            .unwrap()
            .then(() => {
                getReportDetails({
                    isfUserId: user.userId,
                    selectedIsfOrganizationIds: selectedIsfOrganizationIds,
                    dataViewType: DATAVIEWTYPE,
                }).then(() => {
                    setReportUpdated(true);
                    setLoaderStatus(false);
                    setSelectedFilterStatus(true);
                    setShowReportSaveNotification(true);
                    setTimeout(() => {
                        setShowReportSaveNotification(false);
                    }, 6000);
                });
            })
            .catch((e) => {
                setLoaderStatus(false);
                setShowReportSaveErrorNotification(true);
                setTimeout(() => {
                    setShowReportSaveErrorNotification(false);
                }, 6000);
            });
    };

    const notificationMessageUpdate = (indicator) => {
        setNotificationMessageIndicator(indicator);
    };

    const handleReportSavedNotificationClose = () => {
        setShowReportSaveNotification(false);
    };

    const handleReportErrorNotificationClose = () => {
        setShowReportSaveErrorNotification(false);
    };

    const [viewId, setViewId] = useState();

    const handleViewID = (viewId) => {
        setViewId(viewId);
    };

    useEffect(() => {
        if (viewId !== undefined && viewId !== "") {
            updateFrequentlyUsedReportsViewId({
                viewId: viewId,
                parametrs: {
                    IsfUserId: user.userId,
                    SelectedIsfOrganizationIds: selectedIsfOrganizationIds,
                },
            });
        }
    }, [viewId]);

    const handleCoverageType = (parameters) => {
        setCoverageType(parameters);
    };

    useEffect(() => {
        setreportLastFetched(moment().format("MM/DD/YYYY hh:mm A"));
    }, [isFetching, isFetchingPayment, isFetchingLeave]);

    if (reportTableData && Array.isArray(reportTableData.claimsSearch)) {
        //TODO: Leaving this around incase the team changes its mind, this can be removed once
        //portal-users is returning eligibility groups from Agreement
        let fullListOfReportingGroups = [];
        reportTableData.claimsSearch.forEach((claim) => {
            fullListOfReportingGroups.push({
                value: claim.eligibilityGroup,
                label: claim.eligibilityGroup,
            });
        });
        const uniqueReportingGroups = [
            ...new Map(
                fullListOfReportingGroups?.map((item) => [item.value, item])
            ).values(),
        ];
        const noNullsReportingGroups = uniqueReportingGroups.filter(function (
            e
        ) {
            return e.value !== null;
        });
        const sortedReportingGroups = noNullsReportingGroups.sort((a, b) =>
            a.label.localeCompare(b.label)
        );
    }

    const previousDayDate = moment(
        getPreviousDays(reportLastFetched, 1)
    ).format(mmDateFormat);
    const last7DayDate =
        moment(getPreviousDays(reportLastFetched, 7)).format(mmDateFormat) +
        " - " +
        moment(getPreviousDays(reportLastFetched, 1)).format(mmDateFormat);
    const previousMonthDate =
        moment(getPreviousDaysStart(reportLastFetched, "month")).format(
            mmDateFormat
        ) +
        " - " +
        moment(getPreviousDaysEnd(reportLastFetched, "month")).format(
            mmDateFormat
        );
    const previousQuaterDate =
        moment(getPreviousDaysStart(reportLastFetched, "quarter")).format(
            mmDateFormat
        ) +
        " - " +
        moment(getPreviousDaysEnd(reportLastFetched, "quarter")).format(
            mmDateFormat
        );
    const todayDate =
        moment(reportLastFetched).format(mmDateFormat) +
        " - " +
        moment(reportLastFetched).format(mmDateFormat);
    const removeHtmlTags = (content) => {
        const doc = new DOMParser().parseFromString(content, "text/html");
        return doc.body.textContent;
    };

    useEffect(() => {
        if (childRef.current) {
            const span1Content = childRef.current.span1?.innerHTML;
            const span2Content = childRef.current.span2?.innerHTML;
            const span2CleanContent = removeHtmlTags(span2Content);
            const mergingDisclaimerContent =
                span1Content +
                (span2CleanContent !== "undefined" && span2CleanContent !== null
                    ? span2CleanContent
                    : "");
            setDisclaimer(mergingDisclaimerContent);
        }
        if (selectedReport === undefined) {
            if (viewtype === paymentHistoryType) {
                setReportingPeriodValue({
                    value: PREVIOUS_MONTH,
                    label: PREVIOUS_MONTH + previousMonthDate,
                });
            }else if (viewtype === changesReportType) {
                setReportingPeriodValue({
                    value: TODAY,
                    label: TODAY + " " + todayDate,
                });
            } else {
                setReportingPeriodValue({
                    value: LAST_7_DAYS,
                    label: LAST_7_DAYS_LABEL + last7DayDate,
                });
            }
        } 
    }, []);

    let customRangeDate = "";
    if (
        customDateRange[0].selectedFromDate !== null &&
        customDateRange[0].selectedEndDate !== null
    ) {
        customRangeDate =
            moment(customDateRange[0].selectedFromDate).format(mmDateFormat) +
            " - " +
            moment(customDateRange[0].selectedEndDate).format(mmDateFormat);
    } else {
        customRangeDate = "";
    }

    const reporting_Period = [
        { value: PREVIOUS_DAY, label: "Previous day " + previousDayDate },
        { value: LAST_7_DAYS, label: LAST_7_DAYS_LABEL + last7DayDate },
        {
            value: PREVIOUS_MONTH,
            label: PREVIOUS_MONTH + previousMonthDate,
        },
        {
            value: PREVIOUS_QUARTER,
            label: PREVIOUS_QUARTER + previousQuaterDate,
        },
        { value: CUSTOM_DATE, label: CUSTOM_DATE + customRangeDate },
    ];

    const changes_Reporting_Period = [
        { value: TODAY, label: TODAY + " " + todayDate },
        {
            value: PRIOR_BUSINESS_DAY,
            label:
                PRIOR_BUSINESS_DAY +
                " " +
                previousDayDate +
                " - " +
                previousDayDate,
        },
        {
            value: ONE_WEEK,
            label: ONE_WEEK + " " + last7DayDate,
        },
        { value: CUSTOM_DATE, label: CUSTOM_DATE + customRangeDate },
    ];

    function getMappedValues(selectedStatuses) {
        let mappedValues = [];
        if (reportViewType === paymentHistoryType) {
            selectedStatuses.forEach((status) => {
                switch (status) {
                    case "Paid":
                        mappedValues.push("Paid");
                        break;
                    case "Reversal":
                        mappedValues.push(
                            "Reversal - Complete",
                            "Reversal of advice"
                        );
                        break;
                    case "Stop":
                        mappedValues.push("Stop - Complete");
                        break;
                    case "Repayment":
                        mappedValues.push("Refund - Complete");
                        mappedValues.push("Repayment");
                        break;
                    case "Advised":
                        mappedValues.push("Advised");
                        break;
                    case "Active":
                        mappedValues.push("Active");
                        break;
                    case "Cancelled":
                        mappedValues.push("Cancelled");
                        break;
                    case "Replaced":
                        mappedValues.push("Replaced");
                        break;
                }
            });
        } else if (
            reportViewType === disabilityType ||
            changesReportType ||
            leaveType
        ) {
            selectedStatuses.forEach((status) => {
                switch (status) {
                    case "New":
                        mappedValues.push("New", "Pended");
                        break;
                    case "Open":
                        mappedValues.push("Open", "Approved");
                        break;
                    case "Closed":
                        mappedValues.push("Closed", "Closed-Litigation");
                        break;
                }
            });
        }
        return mappedValues;
    }

    const initialFiltersValue = [
        {
            coverageTypeOptions: coverageTypeOptions,
            reporting_Period: reporting_Period,
            status: status,
            PoliciesAndDivisions: allowedPoliciesAndDivisions,
        },
    ];

    const handleApplyFilter = (filterValue) => {
        setLeavesTotalPages(null);
        setClaimsTotalPages(null);
        setEnableApply(false);
        setColumnFilters([]);
        let reportingPeriodStartDate = null;
        let reportingPeriodEndDate = null;
        if (reportingPeriodValue.value === PREVIOUS_DAY) {
            reportingPeriodStartDate = getPreviousDays(reportLastFetched, 1);
            reportingPeriodEndDate = getPresentDayFormat(reportLastFetched);
        } else if (reportingPeriodValue.value === LAST_7_DAYS) {
            reportingPeriodStartDate = getPreviousDays(reportLastFetched, 7);
            reportingPeriodEndDate = getPreviousDays(reportLastFetched, 1);
        } else if (reportingPeriodValue.value === PREVIOUS_MONTH) {
            reportingPeriodStartDate = getPreviousDaysStart(
                reportLastFetched,
                "month"
            );
            reportingPeriodEndDate = getPreviousDaysEnd(
                reportLastFetched,
                "month"
            );
        } else if (reportingPeriodValue.value === PREVIOUS_QUARTER) {
            reportingPeriodStartDate = getPreviousDaysStart(
                reportLastFetched,
                "quarter"
            );
            reportingPeriodEndDate = getPreviousDaysEnd(
                reportLastFetched,
                "quarter"
            );
        }

        if (reportingPeriodValue.value === CUSTOM_DATE) {
            reportingPeriodStartDate = getPresentDayFormat(
                customDateRange[0].startDate
            );
            reportingPeriodEndDate = getPresentDayFormat(
                customDateRange[0].endDate
            );
            setDisableConfirm(true);
        }
        setSelectedFilterStatus(false);
        setReportingPeriodValue(reportingPeriodValue);

        setSelectedFilters(filterValue);

        let filters = {
            organizationId: selectedIsfOrganizationIds,
            claimCoverageTypes: selectedCoverageType.map((item) => item.value),
            claimStatus: getMappedValues(
                reportingStatusValue.map((item) => item.value)
            ),
            effectiveStartDate: reportingPeriodStartDate,
            effectiveEndDate: reportingPeriodEndDate,
            groupAgreementDivision:
                selectedPolicyDivision.length > 500
                    ? ""
                    : selectedPolicyDivision,

            startIndex: 0,
            startPage: 1,
        };

        reportViewType === paymentHistoryType
            ? (filters = {
                  ...filters,
                  paymentStatus: getMappedValues(
                      reportingStatusValue.map((item) => item.value)
                  ),
                  ...(selectedReportingGroup.length > 0 &&
                      selectedReportingGroup.length <= 100 && {
                          reportingGroup: selectedReportingGroup.map(
                              (item) => item.value
                          ),
                      }),
              })
            : (filters = {
                  ...filters,
                  claimStatus: getMappedValues(
                      reportingStatusValue.map((item) => item.value)
                  ),
              });

        if (reportViewType === paymentHistoryType) {
            filters = {
                ...filters,
                count: paymentPagingCount,
            }

            getClaimsPaymentSearch({
                filters: filters,
            });
        } else if (reportViewType === disabilityType) {
            filters = {
                ...filters,
                count: claimPagingCount,
            };

            getClaimsSearch({
                filters: filters,
            });
        } else if (reportViewType === leaveType) {
            getLeavesSearch({
                filters: {
                    organizationId: filters.organizationId,
                    coverageType: filters.claimCoverageTypes,
                    effectiveStartDate: reportingPeriodStartDate,
                    effectiveEndDate: reportingPeriodEndDate,
                    startIndex: filters.startIndex,
                    startPage: filters.startPage,
                    count: leavePagingCount,
                    leaveStatus: reportingStatusValue.map((item) => item.value),
                    leaveScheduleType: periodScheduleTypeValue.map(
                        (item) => item.value
                    ),
                    historicalLeaveInd:
                        includeHistoricalLeaveValue.value === "No" ? "N" : "Y",
                    leavePeriodStatus: periodStatusValue.map(
                        (item) => item.value
                    ),
                    protectionStatus: mapProtectionStatus(
                        protectionStatusValue
                    ),

                    ...(selectedReportingGroup.length > 0 &&
                        selectedReportingGroup.length <= 100 && {
                            reportingGroup: selectedReportingGroup.map(
                                (item) => item.value
                            ),
                        }),
                },
            });
        } else if (reportViewType === absenceType) {
            if (filters.claimCoverageTypes.includes(leaveType)) {
                getLeavesSearch({
                    filters: {
                        organizationId: filters.organizationId,
                        coverageType: filters.claimCoverageTypes.includes(
                            "Leave"
                        )
                            ? "Leave"
                            : "",
                        effectiveStartDate: reportingPeriodStartDate,
                        effectiveEndDate: reportingPeriodEndDate,
                        startIndex: filters.startIndex,
                        startPage: filters.startPage,
                        count: leavePagingCount,
                        leaveStatus: filters.claimStatus,
                        leaveScheduleType: periodScheduleTypeValue.map(
                            (item) => item.value
                        ),
                        historicalLeaveInd:
                            includeHistoricalLeaveValue.value === "No"
                                ? "N"
                                : "Y",
                        leavePeriodStatus: periodStatusValue.map(
                            (item) => item.value
                        ),
                        protectionStatus: mapProtectionStatus(
                            protectionStatusValue
                        ),
                        ...(selectedReportingGroup.length > 0 &&
                            selectedReportingGroup.length <= 100 && {
                                reportingGroup: selectedReportingGroup.map(
                                    (item) => item.value
                                ),
                            }),
                    },
                });
            }
            const disabilityCoverageTypes = ["STD", "SI-STD", "LTD", "SI-LTD"];
            if (
                disabilityCoverageTypes.some((coverageType) =>
                    filters.claimCoverageTypes.includes(coverageType)
                )
            ) {
                filters = {
                    ...filters,
                    count: claimPagingCount,
                };

                getClaimsSearch({
                    filters: filters,
                });
            }
        } else if (reportViewType === changesReportType) {
            getChangesReport({
                startPage: 1,
                count: changePagingCount,
                startDate: getPresentDayFormat(
                    filterValue.reportingPeriod.label.split("-")[0].slice(-11)
                ),
                isfOrganizationIds: selectedIsfOrganizationIds,
            });
        }
    };

    // Assuming PROTECTION_STATUS is imported or defined above
    const mapProtectionStatus = (protectionStatusValue) => {
        return protectionStatusValue.reduce((acc, item) => {
            // Find the matching status in PROTECTION_STATUS
            const matchingStatus = PROTECTION_STATUS.find(
                (status) => status.value === item.value
            );

            if (matchingStatus && matchingStatus.subStatuses) {
                // If there are subStatuses, add them all
                acc.push(
                    ...matchingStatus.subStatuses.map(
                        (subStatus) => subStatus.value
                    )
                );
            } else {
                // Otherwise, add the main status value
                acc.push(item.value);
            }

            return acc;
        }, []);
    };

    useEffect(() => {
        let reportingPeriodStartDate = null;
        let reportingPeriodEndDate = null;
        handleDateRangeChange({
            reset: true,
        });
        const item = reportingPeriodValue;
        if (item.value === PREVIOUS_DAY) {
            reportingPeriodStartDate = getPreviousDays(reportLastFetched, 1);
            reportingPeriodEndDate = getPresentDayFormat(reportLastFetched);
        } else if (item.value === LAST_7_DAYS) {
            reportingPeriodStartDate = getPreviousDays(reportLastFetched, 7);
            reportingPeriodEndDate = getPreviousDays(reportLastFetched, 1);
        } else if (item.value === PREVIOUS_MONTH) {
            reportingPeriodStartDate = getPreviousDaysStart(
                reportLastFetched,
                "month"
            );
            reportingPeriodEndDate = getPreviousDaysEnd(
                reportLastFetched,
                "month"
            );
        } else if (item.value === PREVIOUS_QUARTER) {
            reportingPeriodStartDate = getPreviousDaysStart(
                reportLastFetched,
                "quarter"
            );
            reportingPeriodEndDate = getPreviousDaysEnd(
                reportLastFetched,
                "quarter"
            );
        } else if (item.value === CUSTOM_DATE) {
            reportingPeriodStartDate =
                selectedViewData[0].EffectiveStartDate.substring(0, 10);
            reportingPeriodEndDate =
                selectedViewData[0].EffectiveEndDate.substring(0, 10);
            setCustomDateRange([
                {
                    startDate: selectedViewData[0].EffectiveStartDate.substring(
                        0,
                        10
                    ),
                    endDate: selectedViewData[0].EffectiveEndDate.substring(
                        0,
                        10
                    ),
                    key: "selection",
                    selectedFromDate:
                        selectedViewData[0].EffectiveStartDate.substring(0, 10),
                    selectedEndDate:
                        selectedViewData[0].EffectiveEndDate.substring(0, 10),
                },
            ]);
            setDisableConfirm(true);
        }
        let reportStatus = "";
        if (selectedViewData[0].Status === "All") {
            reportStatus = getMappedValues(status.map((value) => value.value));
        }

        if (coverageType && coverageType !== undefined) {
            if (reportViewType === paymentHistoryType) {
                getClaimsPaymentSearch({
                    filters: {
                        organizationId: selectedIsfOrganizationIds,
                        claimCoverageTypes: coverageType,
                        effectiveStartDate: reportingPeriodStartDate,
                        effectiveEndDate: reportingPeriodEndDate,
                        paymentStatus: reportStatus,
                        startIndex: 0,
                        startPage: 1,
                        count: paymentPagingCount,
                    },
                });
            } else if (reportViewType === disabilityType) {
                getClaimsSearch({
                    filters: {
                        organizationId: selectedIsfOrganizationIds,
                        claimCoverageTypes: coverageType,
                        effectiveStartDate: reportingPeriodStartDate,
                        effectiveEndDate: reportingPeriodEndDate,
                        groupAgreementDivision:
                            selectedPolicyDivision.length > 500
                                ? ""
                                : selectedPolicyDivision,
                        claimStatus: getMappedValues(
                            reportingStatusValue.map((item) => item.value)
                        ),
                        startIndex: 0,
                        startPage: 1,
                        count: claimPagingCount,
                    },
                });
            } else if (reportViewType === leaveType) {
                getLeavesSearch({
                    filters: {
                        organizationId: selectedIsfOrganizationIds,
                        coverageType: coverageType,
                        effectiveStartDate: reportingPeriodStartDate,
                        effectiveEndDate: reportingPeriodEndDate,
                        startIndex: 0,
                        startPage: 1,
                        count: leavePagingCount,
                        leaveScheduleType: periodScheduleTypeValue.map(
                            (item) => item.value
                        ),
                        historicalLeaveInd:
                            includeHistoricalLeaveValue.value === "No"
                                ? "N"
                                : "Y",
                        leavePeriodStatus: periodStatusValue.map(
                            (item) => item.value
                        ),
                        leaveStatus: reportingStatusValue.map(
                            (item) => item.value
                        ),
                        protectionStatus: mapProtectionStatus(
                            protectionStatusValue
                        ),
                    },
                });
            } else if (reportViewType === absenceType) {
                getClaimsSearch({
                    filters: {
                        organizationId: selectedIsfOrganizationIds,
                        claimCoverageTypes: coverageType,
                        effectiveStartDate: reportingPeriodStartDate,
                        effectiveEndDate: reportingPeriodEndDate,
                        claimStatus: reportStatus,
                        groupAgreementDivision:
                            selectedPolicyDivision.length > 500
                                ? ""
                                : selectedPolicyDivision,
                        startIndex: 0,
                        startPage: 1,
                        count: claimPagingCount,
                    },
                });
                getLeavesSearch({
                    filters: {
                        organizationId: selectedIsfOrganizationIds,
                        coverageType: coverageType[4],
                        effectiveStartDate: reportingPeriodStartDate,
                        effectiveEndDate: reportingPeriodEndDate,
                        startIndex: 0,
                        startPage: 1,
                        count: leavePagingCount,
                        leaveScheduleType: periodScheduleTypeValue.map(
                            (item) => item.value
                        ),
                        historicalLeaveInd:
                            includeHistoricalLeaveValue.value === "No"
                                ? "N"
                                : "Y",
                        leavePeriodStatus: periodStatusValue.map(
                            (item) => item.value
                        ),
                        leaveStatus: reportingStatusValue.map(
                            (item) => item.value
                        ),
                        protectionStatus: mapProtectionStatus(
                            protectionStatusValue
                        ),
                    },
                });
            } else if (reportViewType === changesReportType) {
                getChangesReport({
                    startPage: 1,
                    count: changePagingCount,
                    startDate: getPresentDayFormat(
                        reportingPeriodValue.label.match(/\d{2}\/\d{2}\/\d{4}/)[0]
                    ),
                    isfOrganizationIds: selectedIsfOrganizationIds,
                });
            }
        }
        setSelectedFilters({
            ...selectedFilters,
            coverageType: "",
            policiesAndDivisions: [],
        });
    }, [organizationIDISF, coverageType]);

    const handleFiltersDataForSelectedView = (item) => {
        handleFiltersDateForSelectedView(item);
        setSelectedReportingGroup(allowedReportingGroups);
        // Set selected coverage type
        setSelectedCoverageType(
            item.CoverageType.map((code) => ({
                value: code,
                label: code,
            }))
        );

        const groupAgreementDivisions = Array.from(
            new Set(
                item.GroupAgreementDivisions?.flatMap(({ DisplayValue }) => {
                    const policyDivisionValues = DisplayValue.split("-")[0];
                    return [policyDivisionValues, DisplayValue];
                }) || []
            )
        );

        // Sort arrays and find differences
        const sortedInitialPolicyDivision = initialPolicyDivision
            .slice()
            .sort();
        const sortedGroupAgreementDivisionsArray = groupAgreementDivisions
            .slice()
            .sort();

        const missingInGroup = sortedInitialPolicyDivision.filter(
            (item) => !sortedGroupAgreementDivisionsArray.includes(item)
        );
        const extraInGroup = sortedGroupAgreementDivisionsArray.filter(
            (item) => !sortedInitialPolicyDivision.includes(item)
        );

        if (
            (missingInGroup.length === 0 && extraInGroup.length === 0) ||
            item.OwnerType === OWNER_TYPE
        ) {
            setSelectedPolicyDivision(initialPolicyDivision);
        } else {
            setSelectedPolicyDivision(groupAgreementDivisions);
        }
        let modifiedReportingStatus = null;
        if(item.DataViewType !== changesReportType){
            if (item.Status === "All") {
                modifiedReportingStatus = status;
            } else {
                modifiedReportingStatus = item.Status.split(",").map((item) => ({
                    value: item,
                    label: item,
                }));
            }
            setReportingStatusValue(modifiedReportingStatus);
        }

        // Set values based on effective dates
        if (
            item.EffectiveStartDate === STANDARD_REPORT_DATE_TIME &&
            item.EffectiveEndDate === STANDARD_REPORT_DATE_TIME
        ) {
            setPeriodScheduleTypeValue(PERIOD_SCHEDULE_TYPE);
            setIncludeHistoricalLeaveValue({ value: "No", label: "No" });
            setProtectionStatusValue(PROTECTION_STATUS);
            setPeriodStatusValue(PERIOD_STATUS);
        } else {
            setPeriodScheduleTypeValue(
                item?.PeriodScheduleTypes?.map((type) => ({
                    value: type,
                    label: type,
                })) || []
            );
            setIncludeHistoricalLeaveValue({
                value: item.IncludeHistoricalLeave ? "Yes" : "No",
                label: item.IncludeHistoricalLeave ? "Yes" : "No",
            });
        }
    };

    const handleFiltersDateForSelectedView = (item) => {
        const startDate = moment(item.EffectiveStartDate);
        const endDate = moment(item.EffectiveEndDate);
        setSelectedFilters((prev) => ({
            ...prev,
            effectiveStartDate: startDate,
            effectiveEndDate: endDate
        }));
        const differenceInDays = endDate.diff(startDate, "days");

        let reportindPeriodValue = null;
        if (differenceInDays === 1) {
            reportindPeriodValue = {
                value: PREVIOUS_DAY,
                label: PREVIOUS_DAY + previousDayDate,
            };
        } else if (differenceInDays === 6) {
            reportindPeriodValue = {
                value: LAST_7_DAYS,
                label: LAST_7_DAYS_LABEL + last7DayDate,
            };
        } else if (differenceInDays > 25 && differenceInDays < 35) {
            reportindPeriodValue = {
                value: PREVIOUS_MONTH,
                label: PREVIOUS_MONTH + previousMonthDate,
            };
        } else if (differenceInDays > 75 && differenceInDays < 95) {
            reportindPeriodValue = {
                value: PREVIOUS_QUARTER,
                label: PREVIOUS_QUARTER + previousQuaterDate,
            };
        } else if (
            item.EffectiveStartDate === STANDARD_REPORT_DATE_TIME &&
            item.EffectiveEndDate === STANDARD_REPORT_DATE_TIME
        ) {
            if (reportViewType === paymentHistoryType) {
                reportindPeriodValue = {
                    value: PREVIOUS_MONTH,
                    label: PREVIOUS_MONTH + previousMonthDate,
                };
            } else {
                reportindPeriodValue = {
                    value: LAST_7_DAYS,
                    label: LAST_7_DAYS_LABEL + last7DayDate,
                };
            }
        } else {
            reportindPeriodValue = {
                value: CUSTOM_DATE,
                label:
                    CUSTOM_DATE +
                    moment(startDate).format(mmDateFormat) +
                    " - " +
                    moment(endDate).format(mmDateFormat),
            };
        }


        if(item.DataViewType === changesReportType){
            if (differenceInDays === 1) {
                reportindPeriodValue =  {
                    value: PRIOR_BUSINESS_DAY,
                    label:
                        PRIOR_BUSINESS_DAY +
                        " " +
                        previousDayDate +
                        " - " +
                        previousDayDate,
                }
            } else if (differenceInDays === 6) {
                reportindPeriodValue = {
                    value: ONE_WEEK,
                    label: ONE_WEEK + " " + last7DayDate,
                }
            } else if (differenceInDays === 0 ){ 
                reportindPeriodValue=  { value: TODAY, label: TODAY + " " + todayDate }
            } else {
                reportindPeriodValue = {
                    value: CUSTOM_DATE,
                    label:
                        CUSTOM_DATE +
                        moment(startDate).format(mmDateFormat) +
                        " - " +
                        moment(endDate).format(mmDateFormat),
                };
            }
        }
        setReportingPeriodValue({
            value: reportindPeriodValue.value,
            label: reportindPeriodValue.label,
        });

        let modifiedReportingStatus = null;
        if(item.DataViewType !== changesReportType){
            if (item.Status === "All") {
                modifiedReportingStatus = status;
            } else {
                modifiedReportingStatus = item.Status.split(",").map((item) => ({
                    value: item,
                    label: item,
                }));
            }
            setReportingStatusValue(modifiedReportingStatus);
        }
       
        
        if (
            item.EffectiveStartDate === STANDARD_REPORT_DATE_TIME &&
            item.EffectiveEndDate === STANDARD_REPORT_DATE_TIME
        ) {
            setPeriodScheduleTypeValue(PERIOD_SCHEDULE_TYPE);
            setIncludeHistoricalLeaveValue({ value: "No", label: "No" });
            setProtectionStatusValue(PROTECTION_STATUS);
            setPeriodStatusValue(PERIOD_STATUS);
        } else {
            setPeriodScheduleTypeValue(
                item?.PeriodScheduleTypes?.map((item) => ({
                    value: item,
                    label: item,
                }))
            );
            setPeriodStatusValue(
                item?.PeriodStatuses?.map((item) => ({
                    value: item,
                    label: item,
                }))
            );
            setProtectionStatusValue(
                item?.ProtectionStatuses?.map((item) => ({
                    value: item,
                    label: item,
                }))
            );
            const historicalValueToSet = item.IncludeHistoricalLeave
                ? { value: "Yes", label: "Yes" }
                : { value: "No", label: "No" };
            setIncludeHistoricalLeaveValue(historicalValueToSet);
        }
    };

    const handleDateRangeChange = (item) => {
        if (item.reset) {
            setCustomDateRange([
                {
                    startDate: new Date(),
                    endDate: new Date(),
                    key: "selection",
                    label: CUSTOM_DATE,
                    selectedFromDate: null,
                    selectedEndDate: null,
                },
            ]);
        } else {
            if (reportViewType !== changesReportType) {
                if (item.confirm) {
                    setReportingPeriodValue({
                        value: CUSTOM_DATE,
                        label:
                            CUSTOM_DATE +
                            moment(customDateRange[0].startDate).format(mmDateFormat) +
                            " - " +
                            moment(customDateRange[0].endDate).format(mmDateFormat),
                    });
                    setEnableReset(true);
                    setEnableApply(true);
                } else {
                    if (selectionMode === "start") {
                        // Odd clicks (1st, 3rd, 5th, etc.) set startDate
                        setCustomDateRange([
                            {
                                ...customDateRange[0],
                                startDate: item.selection.startDate,
                                selectedFromDate: item.selection.startDate,
                                endDate: item.selection.startDate,
                                key: "selection",
                                label: CUSTOM_DATE,
                                selectedEndDate: null,
                            },
                        ]);
                        setSelectionMode("end");
                    } else if (selectionMode === "end") {
                        setCustomDateRange([
                            {
                                ...customDateRange[0],
                                startDate: item.selection.startDate,
                                selectedFromDate: item.selection.startDate,
                                endDate: item.selection.endDate,
                                selectedEndDate: item.selection.endDate,
                                key: "selection",
                                label: CUSTOM_DATE,
                            },
                        ]);
                        setSelectionMode("start");
                        setDisableConfirm(false);
                    }
                }
            } else {
                if (item.confirm) {
                    setReportingPeriodValue({
                        value: CUSTOM_DATE,
                        label:
                            CUSTOM_DATE +
                            moment(customDateRange[0].startDate).format(mmDateFormat) +
                            " - " +
                            moment(customDateRange[0].endDate).format(mmDateFormat),
                    });
                    setEnableReset(true);
                    setEnableApply(true);
                } else {
                    setCustomDateRange([
                            {
                                ...customDateRange[0],
                                startDate: item.selection.startDate,
                                selectedFromDate: item.selection.startDate,
                                endDate: new Date(),
                                selectedEndDate: new Date(),
                                key: "selection",
                                label: CUSTOM_DATE,
                            },
                    ]);
                    setDisableConfirm(false);
                }
                
            }
        }
        
    };


    useEffect(() => {
        if (
            reportClaimsTableData &&
            Array.isArray(reportClaimsTableData.claimsSearch) &&
            reportLeaveTableData &&
            Array.isArray(reportLeaveTableData.LeaveSearch)
        ) {
            let formattedAbsenceViewData = [];
            if (!selectedCoverageType.some((cov) => cov.value === leaveType)) {
                formattedAbsenceViewData = AbsenceSearch.format(
                    [],
                    reportClaimsTableData
                );
            } else if (
                selectedCoverageType.some((cov) => cov.value === leaveType) &&
                selectedCoverageType.length === 1
            ) {
                formattedAbsenceViewData = AbsenceSearch.format(
                    reportLeaveTableData,
                    []
                );
            } else {
                formattedAbsenceViewData = AbsenceSearch.format(
                    reportLeaveTableData,
                    reportClaimsTableData
                );
            }
            const getFilteredAbsenceViewData=formattedAbsenceViewData.filter((item)=>{
                if(protectionStatusValue.length && protectionStatusValue.length!==PROTECTION_STATUS.length && periodStatusValue.length===PERIOD_STATUS.length){
                    return protectionStatusValue.map((items)=>items.label).includes(item.protectionStatusDesc) 
                }
                if(periodStatusValue.length && periodStatusValue.length!==PERIOD_STATUS.length && protectionStatusValue.length===PROTECTION_STATUS.length){
                    return periodStatusValue.map((items)=>items.label).includes(item.periodStatus)
                }
                if(protectionStatusValue.length &&  protectionStatusValue.length !==PROTECTION_STATUS.length && periodStatusValue.length && periodStatusValue.length!==PERIOD_STATUS.length){                        
                    return protectionStatusValue.map((items)=>items.label).includes(item.protectionStatusDesc)  && periodStatusValue.map((items)=>items.label).includes(item.periodStatus)
                }
                return formattedAbsenceViewData
            })            
            setAbsenceViewData(getFilteredAbsenceViewData);
        }
        if (reportClaimsTableData && reportClaimsTableData.searchResultMetadata && reportClaimsTableData.searchResultMetadata.totalResults) {
            setTotalResults(reportClaimsTableData.searchResultMetadata.totalResults);
        }
         if (reportLeaveTableData && reportLeaveTableData.searchResultMetadata && reportLeaveTableData.searchResultMetadata.totalResults) {
            setTotalResults(reportLeaveTableData.searchResultMetadata.totalResults);
        }
    }, [
        reportClaimsTableData,
        reportLeaveTableData,
        isFetchingLeave,
        isFetchingLeaveChages,
    ]);
    
    useEffect(() => {
            if ((!isFetchingClaimsPaging && !isUninitializedClaimsPaging) &&
                (!isFetchingLeavePaging && !isUninitializedLeavePaging)) {
                let formattedAbsenceViewPageData = [];
                formattedAbsenceViewPageData = AbsenceSearch.format(
                    reportLeaveTableDataPaging,    
                    reportClaimsTableDataPage,
                       
                );
                // Append the new data to the existing absenceViewData
                setAbsenceViewData((prevAbsenceViewData) => [
                    ...prevAbsenceViewData,
                    ...formattedAbsenceViewPageData,
                ]);
            }
        }, [
            reportClaimsTableDataPage,
            reportLeaveTableDataPaging,
            isFetchingClaimsPaging,
            isFetchingLeavePaging,
    ]);
    
    useEffect(() => {
        if (totalPages >= pagecounter + 1) {
            setPageCounter(prevCounter => {
                const newCounter = prevCounter + 1;
                loadPagingData(newCounter);
                return newCounter;
            });
        }
    }, [totalResults, reportTableDataValue, reportTableData]);

    useEffect(() => {
        if (absenceTotalpages >= pagecounter + 1) {
            setPageCounter(prevCounter => {
                const newCounter = prevCounter + 1;
                loadPagingData(newCounter);
                return newCounter;
            });
        }
    }, [absenceTotalpages, absenceViewData]);
    
    useEffect(() => {
        reportTableDataValue = reportTableData;
    }, [reportTableData]);

    const handleCoverage = (coverage) => {
        setSelectedCoverageType(coverage);
        setEnableReset(true);
        setEnableApply(true);
    };
    const handleStatus = (status) => {
        setReportingStatusValue(status);
        setEnableReset(true);
        setEnableApply(true);
    };
    const handlePeriodScheduleTypeValueChange = (item) => {
        setPeriodScheduleTypeValue(item);
        setEnableReset(true);
        setEnableApply(true);
    };

    const handleIncludeHistoricalValueChange = (item) => {
        setIncludeHistoricalLeaveValue(item);
        setEnableReset(true);
        setEnableApply(true);
    };

    const handlePeriodStatusValueChange = (item) => {
        setPeriodStatusValue(item);
        setEnableReset(true);
        setEnableApply(true);
    };

    const handleProtectionStatusValueChange = (item) => {
        setProtectionStatusValue(item);
        setEnableReset(true);
        setEnableApply(true);
    };

    const handleReportingPeriodChange = (item) => {
        setReportingPeriodValue(item);
        setEnableReset(true);
        setEnableApply(true);
    };

    const handleResetFilter = () => {
        handleFiltersDataForSelectedView(selectedViewData[0]);
        handleDateRangeChange({
            reset: true,
        });
        setEnableReset(false);
        setEnableApply(true);
        setColumnFilters([]);
    };

    const onHandleOutsideClick = () => {
        if (reportingPeriodValue.label === CUSTOM_DATE) {
            handleFiltersDateForSelectedView(selectedViewData[0]);
            handleDateRangeChange({
                reset: true,
            });
        } else {
            if (reportingPeriodValue.value !== CUSTOM_DATE) {
                handleDateRangeChange({
                    reset: true,
                });
            }
            setReportingPeriodValue(reportingPeriodValue);
        }
        setEnableReset(true);
        setEnableApply(true);
    };

    let isFetchingValue;
    let isFetchingMoreValue;

    if (reportViewType === disabilityType) {
        isFetchingValue = isFetching;
        isFetchingMoreValue = isFetchingClaimsPaging;
    } else if (reportViewType === paymentHistoryType) {
        isFetchingValue = isFetchingPayment;
    } else if (reportViewType === leaveType) {
        isFetchingValue = isFetchingLeave;
    } else if (reportViewType === absenceType) {
        isFetchingValue = isFetching || isFetchingLeave;
    } else if (reportViewType === changesReportType) {
        isFetchingValue = isFetchingLeaveChages;
    } else {
        isFetchingValue = false;
    }

    if (reportViewType === disabilityType) {
        reportTableDataValue = reportTableData;
    } else if (reportViewType === paymentHistoryType) {
        reportTableDataValue = reportTableData;
    } else if (reportViewType === leaveType) {
        reportTableDataValue = reportTableData;
    } else if (reportViewType === absenceType) {
        reportTableDataValue = absenceViewData;
    } else if (reportViewType === changesReportType) {
        reportTableDataValue = reportTableData;
    } else {
        reportTableDataValue = "";
    }

    let initializedValue;

    if (reportViewType === disabilityType) {
        initializedValue = isUninitialized;
    } else if (reportViewType === paymentHistoryType) {
        initializedValue = isUninitializedPayment;
    } else if (reportViewType === leaveType) {
        initializedValue = isUninitializedLeave;
    } else if (reportViewType === absenceType) {
        initializedValue = isUninitialized && isUninitializedLeave;
    } else if (reportViewType === changesReportType) {
        initializedValue = isUninitializedLeaveChanges;
    } else {
        initializedValue = false;
    }

    let isErrorValue;

    if (reportViewType === disabilityType) {
         isErrorValue = isError || isErrorClaimsPaging;
    } else if (reportViewType === paymentHistoryType) {
        isErrorValue = isErrorPayment || isErrorPaymentPaging;
    } else if (reportViewType === leaveType) {
        isErrorValue = isErrorLeave || isErrorLeavePaging;
    } else if (reportViewType === absenceType) {
        isErrorValue = isError || isErrorLeave || isErrorLeavePaging || isErrorClaimsPaging;
    } else if (reportViewType === changesReportType) {
        isErrorValue = isErrorLeaveChanges || isErrorLeaveChangesPaging;
    } else {
        isErrorValue = false;
    }

    return (
        <>
            <ReportViewFilter
                organizationName={organizationName}
                userSelectedOrganizationReports={
                    userSelectedOrganizationReports
                }
                reportId={reportId}
                relatedOrganizationNames={relatedOrganizationNames}
                title={
                    selectedReport?.DataViewType
                        ? selectedReport?.DataViewType
                        : title
                }
                coverageType={coverageType}
                isFetching={isFetchingValue}
                allowedPoliciesAndDivisions={allowedPoliciesAndDivisions}
                //filters
                coverageTypeOptions={coverageTypeOptions}
                reportingPeriod={
                    reportViewType === changesReportType
                        ? changes_Reporting_Period
                        : reporting_Period
                }
                status={status}
                reportingGroup={allowedReportingGroups}
                handleApplyFilter={handleApplyFilter}
                selectedFilters={selectedFilters}
                reportRanDate={reportLastFetched}
                reportViewType={reportViewType}
                defaultReportingPeriod={reportingPeriodValue}
                defaultReportingStatus={reportingStatusValue}
                customDateRange={customDateRange}
                handleDateRangeChange={handleDateRangeChange}
                selectedCoverageType={selectedCoverageType}
                handleCoverage={handleCoverage}
                selectedStatus={reportingStatusValue}
                handleStatus={handleStatus}
                selectedPolicyDivision={selectedPolicyDivision}
                handlePolicyDivision={handlePolicyDivision}
                selectedReportingGroup={selectedReportingGroup}
                handleReportingGroup={handleReportingGroup}
                initialPolicyDivision={initialPolicyDivision}
                includeHistoricalLeave={INCLUDE_HISTORICAL_LEAVE}
                periodScheduleType={PERIOD_SCHEDULE_TYPE}
                periodStatus={PERIOD_STATUS}
                protectionStatus={PROTECTION_STATUS}
                includeHistoricalLeaveValue={includeHistoricalLeaveValue}
                periodScheduleTypeValue={periodScheduleTypeValue}
                periodStatusValue={periodStatusValue}
                protectionStatusValue={protectionStatusValue}
                onIncludeHistoricalValueChange={
                    handleIncludeHistoricalValueChange
                }
                onPeriodScheduleTypeValueChange={
                    handlePeriodScheduleTypeValueChange
                }
                onPeriodStatusValueChange={handlePeriodStatusValueChange}
                onProtectionStatusValueChange={
                    handleProtectionStatusValueChange
                }
                onReportingPeriodChange={handleReportingPeriodChange}
                onHandleReset={handleResetFilter}
                enableReset={enableReset}
                enableApply={enableApply}
                onHandleOutsideClick={onHandleOutsideClick}
                disableConfirm={disableConfirm}
                claimData={reportTableDataValue || []}
                totalPages={reportViewType === absenceType ? absenceTotalpages : totalPages}
                pagecounter={pagecounter}
            />
            <ReportViewData
                organizationIDISF={organizationIDISF}
                selectedReport={selectedReport}
                reportData={reportData}
                dataViewType={reportViewType}
                reportViewInformation={selectedViewData}
                onViewOptionChange={handleViewOptionChange}
                onViewReportSave={handleViewReportSave}
                allViewsData={allViewsData}
                loaderStatus={loaderStatus}
                onViewReportUpdate={handleViewReportUpdate}
                reportTableData={reportTableDataValue}
                isFetching={isFetchingValue}
                isUninitialized={initializedValue}
                isError={isErrorValue}
                handleCoverageType={handleCoverageType}
                notificationStatus={notificationMessageUpdate}
                handleViewID={handleViewID}
                reportLastFetched={reportLastFetched}
                userEmail={user.userEmail}
                filterValues={selectedFilters}
                initialFiltersValue={initialFiltersValue}
                portalUserApiError={portalUserApiError}
                selectedPolicyDivision={selectedPolicyDivision}
                organizationsAccess={organizationsAccess[reportId]}
                emitFiltersDataForSelectedView={
                    handleFiltersDataForSelectedView
                }
                selectedFilterStatus={selectedFilterStatus}
                reportingPeriodValue={reportingPeriodValue}
                reportingStatusValue={reportingStatusValue}
                selectedCoverageType={selectedCoverageType}
                disclaimer={disclaimer}
                totalResults={totalResults}
                isFetchingMore={isFetchingMoreValue}
                portalUserData={portalUserData}
                includeHistoricalLeaveValue={includeHistoricalLeaveValue}
                periodScheduleTypeValue={periodScheduleTypeValue}
                periodStatusValue={periodStatusValue}
                protectionStatusValue={protectionStatusValue}
                totalPages={reportViewType === absenceType ? absenceTotalpages : totalPages}
                pagecounter={pagecounter}
                isDataFormattingComplete={isDataFormattingComplete}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
            />
            {isFetching || isFetchingPayment ? (
                <FooterNote date="..." reportViewType={reportViewType} />
            ) : (
                <FooterNote
                    date={reportLastFetched}
                    reportViewType={reportViewType}
                    ref={childRef}
                />
            )}
            {showReportSaveNotification && (
                <ReportSaveNotification
                    onCloseNotification={handleReportSavedNotificationClose}
                    msgIndicator={notificationMessageIndicator}
                ></ReportSaveNotification>
            )}
            {showReportSaveErrorNotification && (
                <ReportSaveErrorNotification
                    onCloseNotification={handleReportErrorNotificationClose}
                ></ReportSaveErrorNotification>
            )}
        </>
    );
}

export default ReportViewPage;
