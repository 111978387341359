import React, { useState, useEffect, useRef } from "react";
import { Breadcrumb } from "../../components/ui/Breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import Divider from "../../components/ui/Divider";
import Button from "../../components/ui/Button";
import EmployeeSearchPreference from "./EmployeeSearchPreference";
import HomeSearchPreferences from "./HomeSearchPreferences";
import {
    setUserPreference,
    setPreferenceChanged,
} from "../../slices/preferenceSlice";
import {
    addLastSelectedOrganization,
    addSelectedOrganization,
} from "../../slices/organizationSlice";
import OrganizationRepository from "../../repositories/OrganizationRepository";
import { useEventContext } from "../../EventContext";
import OrganizationSearchPreferenceComponent from "../../components/OrganizationSearchPreferenceComponent";
import Loader from "../../components/ui/Loader";
import RequestFailed from "../../components/alerts/RequestFailed";

import { useFlags } from "launchdarkly-react-client-sdk";
import { useUpdateUserDataMutation } from "../../slices/userPreferenceApiSlice";
import GTM from "../../GTM";

export default function Preferences({ setIsPreferenceSaved }) {
    const [updateUserDataMutation] = useUpdateUserDataMutation();
    const { user } = useSelector((state) => state.auth);
    const {
        parentOrganizations: organizations,
        organizations: allOrganizations,
        policies,
        reportEnabledOrganizations: reportEnabledOrganizations,
    } = useSelector((state) => state.userDetails);

    const {
        searchPreference,
        homePreference,
        userSelectedOrganizationPreference = [],
        organizationPreference,
    } = useSelector((state) => state.preferenceSlice);

    const {
        selectedOrganizations: userSelectedOrganizations,
        organizationsCount,
    } = useSelector((state) => state.organizations);

    const { totalParentOrganizations } = useSelector(
        (state) => state.organizations
    );
    const dispatch = useDispatch();

    const {
        displayReports,
        displayReportsForInternals,
        displayReportsForChangeChampions,

        displayReportsForOrgs,
    } = useFlags();
    const enabledUsersForReports = displayReportsForChangeChampions?.split(",");
    const [organizationsSelected, setOrganizationsSelected] = useState([]);
    const [selectedRelatedOrganizations, setSelectedRelatedOrganizations] =
        useState([]);
    const [selectedDropdownValue, setSelectedDropdownValue] = useState([]);
    const [selectedEmployeePreference, setSelectedEmployeePreference] =
        useState([]);

    const [relatedOrgIdsMatchPreference, setRelatedOrgIdsMatchPreference] =
        useState(false);
    const [isSavingPreference, setIsSavingPreference] = useState(false);
    const [isDisabled, setIsDisabled] = useState();
    const [isError, setIsError] = useState(false);
    const { eventData } = useEventContext();
    const previousEventData = useRef(null);
    const isInitialLoad = useRef(true);
    const gtm = new GTM(null);

    useEffect(() => {
        if (!isInitialLoad.current) {
            if (previousEventData.current !== eventData) {
                handleSaveAndReturn();
            }
        } else {
            isInitialLoad.current = false;
        }
        previousEventData.current = eventData;
    }, [eventData]);

    useEffect(() => {
        setIsPreferenceChangedValue(false);
    }, []);

    const setIsPreferenceChangedValue = (value) => {
        dispatch(setPreferenceChanged(value));
    };
    //to show warning popup for releted org and to check related org to disable status of save button
    useEffect(() => {
        const getAllSelectedReletedOrgIds = selectedRelatedOrganizations.map(
            (org) => org.value
        );

        const checkOrgIdsSame = getAllSelectedReletedOrgIds
            .toString()
            .split(",")
            .every((value) =>
                userSelectedOrganizationPreference
                    .toString()
                    .split(",")
                    .includes(value)
            );

        setRelatedOrgIdsMatchPreference(
            checkOrgIdsSame &&
                getAllSelectedReletedOrgIds.length ===
                    userSelectedOrganizationPreference.length
        );

        if (checkOrgIdsSame) {
            setIsPreferenceChangedValue(false);
        } else {
            setIsPreferenceChangedValue(true);
        }
    }, [selectedRelatedOrganizations]);

    const organizationSelected = (value) => {
        setOrganizationsSelected(value);
        if (
            userSelectedOrganizationPreference?.toString() !==
            organizationsSelected?.toString()
        ) {
            setIsPreferenceChangedValue(true);
        } else {
            setIsPreferenceChangedValue(false);
        }
    };

    const handleSelectedRelatedOrganizations = (selectedOrgs) => {
        setSelectedRelatedOrganizations(selectedOrgs);
    };

    const onHomeScreenPreferenceSelected = (value) => {
        setSelectedDropdownValue(value);
        if (homePreference?.toString() !== value) {
            setIsPreferenceChangedValue(true);
        } else {
            setIsPreferenceChangedValue(false);
        }
        gtm.Event(
            "custom_event",
            "dropdown",
            "select",
            "set home screen preference",
            "preferences",
            "",
            "field_interaction",
            value === "reportingcapabilities" ? "Reports" : value
        );
    };

    const onEmployeePreferenceSelected = (value) => {
        setSelectedEmployeePreference(value);
        if (searchPreference?.toString() !== value) {
            setIsPreferenceChangedValue(true);
        } else {
            setIsPreferenceChangedValue(false);
        }

        gtm.Event(
            "custom_event",
            "dropdown",
            "select",
            "set employee search preference",
            "preferences",
            "",
            "field_interaction",
            value
        );
    };

    const selectedRelatedOrg = selectedRelatedOrganizations?.map((org) =>
        org?.value?.toString()
    );

    const updatePreference = () => {
        const dataToUpdate = {
            OrganizationPreference:
                selectedRelatedOrganizations?.length > 0
                    ? selectedRelatedOrg
                    : organizationsSelected !== null
                    ? [organizationsSelected?.toString()]
                    : [""],
        };

        if (selectedDropdownValue) {
            dataToUpdate.HomePreference = [selectedDropdownValue.toString()];
        }

        if (selectedEmployeePreference) {
            dataToUpdate.SearchPreference = [
                selectedEmployeePreference.toString(),
            ];
        }
        setIsSavingPreference(true);
        setIsError(false);
        gtm.Event(
            "custom_event",
            "button",
            "click",
            "save",
            "preferences",
            "",
            "save_preferences",
            selectedEmployeePreference
        );

        updateUserDataMutation({
            userId: user.userId,
            dataToUpdate,
        })
            .unwrap()
            .then(() => {
                setIsPreferenceSaved(true);
                setIsSavingPreference(false); // Set loading state back to false
            })
            .catch((e) => {
                setIsError(true);
            });

        dispatch(
            setUserPreference({
                homePreference: dataToUpdate.HomePreference,
                organizationPreference: dataToUpdate.OrganizationPreference,
                userSelectedOrganizationPreference:
                    dataToUpdate.OrganizationPreference,
                searchPreference: dataToUpdate.SearchPreference,
            })
        );
        setIsPreferenceChangedValue(false);
    };

    useEffect(() => {
        const preferenceStoredOrg =
            OrganizationRepository.getRelatedOrganizationsFromIds(
                allOrganizations,
                userSelectedOrganizationPreference
            );

        if (preferenceStoredOrg.length > 0) {
            dispatch(
                addSelectedOrganization({
                    id: preferenceStoredOrg[0].ORGANIZATIONID,
                    organization: preferenceStoredOrg[0],
                    related:
                        preferenceStoredOrg[0]?.ORGANIZATIONGROUPID !== null
                            ? preferenceStoredOrg
                            : [],
                })
            );

            dispatch(addLastSelectedOrganization(preferenceStoredOrg[0]));
        }
    }, [userSelectedOrganizationPreference, allOrganizations]);

    const handleSaveAndReturn = () => {
        updatePreference();
    };

    useEffect(() => {
        // Check for changes in preferences and reset isPreferenceSaved
        const preferencesChanged =
            JSON.stringify(searchPreference) !==
                JSON.stringify(selectedEmployeePreference) ||
            JSON.stringify(homePreference) !==
                JSON.stringify(selectedDropdownValue) ||
            JSON.stringify(userSelectedOrganizationPreference) !==
                JSON.stringify(selectedRelatedOrganizations);

        if (preferencesChanged) {
            setIsPreferenceSaved(false);
        }
    }, [
        searchPreference,
        homePreference,
        userSelectedOrganizationPreference,
        selectedEmployeePreference,
        selectedDropdownValue,
    ]);

    // Disable Save Button

    const employeeSearch =
        searchPreference?.toString() === selectedEmployeePreference ||
        (searchPreference?.toString() === undefined &&
            selectedEmployeePreference == "lastName");

    const defaultHome =
        homePreference?.toString() === selectedDropdownValue?.toString() ||
        homePreference === undefined;

    useEffect(() => {
        let isDisabled;

        if (selectedRelatedOrganizations.length) {
            isDisabled =
                relatedOrgIdsMatchPreference === true &&
                defaultHome &&
                employeeSearch;
        } else {
            isDisabled =
                organizationsSelected?.toString() ===
                    userSelectedOrganizationPreference?.toString() &&
                defaultHome &&
                employeeSearch;
        }

        setIsDisabled(isDisabled);
    }, [
        defaultHome,
        employeeSearch,
        userSelectedOrganizationPreference,
        organizationsSelected,
        relatedOrgIdsMatchPreference,
        selectedRelatedOrganizations,
    ]);

    const getButtonDisabledStatus = () => isDisabled;
    //
    const shouldRedirectToReport =
        (displayReportsForChangeChampions &&
            enabledUsersForReports?.includes(user.userId)) ||
        (displayReportsForInternals && user.isUserInternal) ||
        (displayReportsForOrgs &&
            reportEnabledOrganizations?.EnabledOrganizations?.some((org) =>
                allOrganizations
                    .map((org) => org.ORGANIZATIONIDISF)
                    .includes(org)
            ));

    const getPreferenceComponent = () => {
        return (
            <div>
                <div className="d-flex section-card flex-direction">
                    <h5>
                        <font color="#015294">Preferences for </font>
                        <font color="#525252">
                            {user ? user.userEmail : ""}
                        </font>
                    </h5>
                    <Divider className="my-24p" mx="" />

                    {organizations.length > 1 && (
                        <>
                            <OrganizationSearchPreferenceComponent
                                organizations={organizations}
                                organizationSelected={organizationSelected}
                                onSelectedRelatedOrganizationsChange={
                                    handleSelectedRelatedOrganizations
                                }
                                allOrganizations={allOrganizations}
                                organizationPreference={
                                    userSelectedOrganizationPreference
                                }
                                totalParentOrganizations={
                                    totalParentOrganizations
                                }
                                userSelectedOrganizations={
                                    userSelectedOrganizations
                                }
                                organizationsCount={organizationsCount}
                                policies={policies}
                                clearButtonText="Clear preference"
                                setIsPreferenceSaved={setIsPreferenceSaved}
                            />
                            <Divider className="my-24p" mx="" />
                        </>
                    )}

                    <EmployeeSearchPreference
                        onEmployeePreferenceSelected={
                            onEmployeePreferenceSelected
                        }
                        searchPreference={searchPreference}
                    />
                    <Divider className="my-24p" mx="" />
                    {shouldRedirectToReport ? (
                        <>
                            <HomeSearchPreferences
                                onHomeScreenPreferenceSelected={
                                    onHomeScreenPreferenceSelected
                                }
                            />
                            <Divider className="my-24p" mx="" />
                        </>
                    ) : (
                        <></>
                    )}

                    <div className="d-flex col-lg-2 col-md-3 col-sm-6 col-6 a-self">
                        <Button
                            onClick={handleSaveAndReturn}
                            text="Save"
                            disabled={getButtonDisabledStatus()}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const checkSavePreferenceApiResponse = () => {
        if (isSavingPreference && !isError) {
            return <Loader text="Saving..." />;
        }
        if (isError) {
            return (
                <div className="mt-0p">
                    <RequestFailed className="0px" preferencePageError={true} />
                    {getPreferenceComponent()}
                </div>
            );
        }
        if (!isSavingPreference && !isError) {
            return <>{getPreferenceComponent()}</>;
        }
    };

    return (
        <div>
            <Breadcrumb
                links={[
                    {
                        path: "/search",
                        name: "Search",
                    },
                    {
                        name: "Preferences",
                    },
                ]}
            />
            <div>{checkSavePreferenceApiResponse()}</div>
        </div>
    );
}
