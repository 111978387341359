import React, { useEffect, useState } from "react";
import SectionHeading from "../../components/SectionHeading";
import { Breadcrumb } from "../../components/ui/Breadcrumb";
import reportIcon from "../../assets/icons/report-icon.svg";
import { useSelector, useDispatch } from "react-redux";
import ReportViews from "./ReportViews";
import { useFlags } from "launchdarkly-react-client-sdk";
import OrganizationSearchBaseComponent from "../../components/OrganizationSearchBaseComponent";
import GTM from "../../GTM";
import { setSelectedRelatedOrganizationsForReports } from "../../slices/viewReportSlice";
import { isCostcoUser } from "../../utils/helpers";

function ViewSectionPage(props) {
    const {
        displayReportsForInternals,
        displayReportsForOrgs,
        displayReportsForChangeChampions,
    } = useFlags();
    const enabledUsersForReports = displayReportsForChangeChampions?.split(",");
    const { user } = useSelector((state) => state.auth);
    const { config } = useSelector((state) => state.app);
    const dispatch = useDispatch();
    const {
        parentOrganizations: organizations,
        organizations: allOrganizations,
        policies,
        reportEnabledOrganizations: reportEnabledOrganizations,
    } = useSelector((state) => state.userDetails);
    const {
        selectedOrganizations: userSelectedOrganizations,
        selectedOrganizationsId: id,
        totalParentOrganizations,
        organizationsCount,
    } = useSelector((state) => state.organizations);

    const {
        selectedOrganizationsReports: userSelectedOrganizationReports,
        selectedOrganizationsIdReports: reportId,
        selectedRelatedOrganizationsForReports: selectedRelatedOrgForReports,
    } = useSelector((state) => state.reportOrganization);

    const [organizationsSelected, setOrganizationsSelected] = useState([]);

    let previousUrls = props.previousUrl;
    let page_referrer = previousUrls[previousUrls.length - 1];

    const gtm = new GTM(null);
    const employeePage = /organization\/[^\/]+\/employees\/search/s;
    const claimLeavePage = /organization\/[^\/]+\/employees\/[^\/]+/gm;
    const reportPage = /reportingcapabilities\/(.+)+/gm;

    const getPageReferrerTitle = (page_referrer_url, user) => {
        const clearReportsSelection = () =>
            dispatch(setSelectedRelatedOrganizationsForReports([]));

        if (user.isUserInternal && page_referrer_url.includes("/login")) {
            return "internal";
        } else if (
            page_referrer_url.match(employeePage) &&
            !page_referrer_url.includes("/login")
        ) {
            return "employee search";
        } else if (page_referrer_url.match(claimLeavePage)) {
            return "claim & leave";
        } else if (page_referrer_url.includes("/home")) {
            return "home";
        } else if (page_referrer_url.includes("/help")) {
            clearReportsSelection();
            return "help";
        } else if (page_referrer_url.includes("/preferences")) {
            return "preferences";
        } else if (page_referrer_url.match(reportPage)) {
            const array = page_referrer_url.match(reportPage);
            const lastText = array[array?.length - 1];
            return `reports ${lastText?.split("/")[1].toLowerCase()}`;
        } else if (
            (page_referrer_url.includes("/login") &&
                page_referrer_url.includes("uaservices")) ||
            page_referrer_url.includes("services")
        ) {
            return "iservices";
        } else {
            return "search";
        }
    };

    const page_referrer_url =
        page_referrer === "/login"
            ? `${config.REACT_APP_ISERVICES}:81/LanAuthentication`
            : `${config.REACT_APP_BASE_URL}${page_referrer}`;

    const page_referrer_title = getPageReferrerTitle(page_referrer_url, user);

    useEffect(() => {
        const userName = "";
        const userId = user ? user.userId : "";
        const userRole = user.isUserInternal ? "internal" : "external";

        gtm.PageView(
            "run reports ",
            userName,
            userId,
            userRole,
            window.location.href,
            `${config.REACT_APP_BASE_URL}${page_referrer}`,
            page_referrer_title,
            ""
        );
    }, [20000]);

    const [selectedRelatedOrganizations, setSelectedRelatedOrganizations] =
        useState([]);
    const { organizationPreference = [] } = useSelector(
        (state) => state.preferenceSlice
    );

    const organizationSelected = (value) => {
        setOrganizationsSelected(value);
    };

    const handleSelectedRelatedOrganizations = (selectedOrgs) => {
        setSelectedRelatedOrganizations(selectedOrgs);
    };

    const displayReportView =
        totalParentOrganizations === 1 &&
        userSelectedOrganizationReports[reportId]?.related?.length === 0
            ? true
            : userSelectedOrganizationReports[reportId]?.related?.length > 0
            ? selectedRelatedOrgForReports?.length > 0
            : organizationsSelected?.length > 0;

    const handleClick = () => {
        if (user?.isUserInternal) {
            window.open(config.REACT_APP_CLI_INTERNAL, "_blank");
        } else {
            window.open(config.REACT_APP_CLI, "_blank");
        }
    };

    return (
        <>
            <Breadcrumb
                links={[
                    {
                        path: "/",
                        name: "Reports",
                    },
                    {
                        name: "Run reports",
                    },
                ]}
            />
            {(displayReportsForInternals && user.isUserInternal) ||
            (displayReportsForChangeChampions &&
                enabledUsersForReports?.includes(user.userId)) ||
            (displayReportsForOrgs &&
                reportEnabledOrganizations?.EnabledOrganizations?.some((org) =>
                    allOrganizations
                        .map((org) => org.ORGANIZATIONIDISF)
                        .includes(org)
                )) ? (
                <>
                    <div className="section-card view-selection-page">
                        <div
                            className="d-flex header"
                            style={{ alignItems: "center", gap: 8 }}
                        >
                            <img
                                className="icon"
                                src={reportIcon}
                                alt="Report"
                            />
                            <SectionHeading heading="Reports" mb="mb-0" />

                            {organizations.length > 1 ? (
                                ""
                            ) : (
                                <>
                                    <div className="vr"></div>
                                    <label className="sub-header">
                                        {userSelectedOrganizations[id]
                                            ?.related[0]?.ORGANIZATIONGROUPNAME
                                            ? userSelectedOrganizations[id]
                                                  ?.related[0]
                                                  ?.ORGANIZATIONGROUPNAME
                                            : userSelectedOrganizations[id]
                                                  ?.organization
                                                  ?.ORGNIZATIONNAME}
                                    </label>
                                </>
                            )}

                            {userSelectedOrganizations[id]?.related.length >
                            1 ? (
                                <>
                                    <div className="vr"></div>
                                    <label className="sub-header">
                                        {userSelectedOrganizations[id]
                                            ?.related[0].ORGANIZATIONGROUPNAME
                                            ? userSelectedOrganizations[id]
                                                  ?.related[0]
                                                  .ORGANIZATIONGROUPNAME
                                            : userSelectedOrganizations[id]
                                                  ?.organization
                                                  ?.ORGNIZATIONNAME}
                                    </label>
                                </>
                            ) : (
                                ""
                            )}
                            {userSelectedOrganizationReports[reportId]?.related
                                ?.length > 0 &&
                                totalParentOrganizations > 1 &&
                                displayReportView && (
                                    <>
                                        <div
                                            className="vr text-color"
                                            style={{ width: 2 }}
                                        ></div>
                                        <label
                                            className="sub-heading-disabled"
                                            style={{
                                                paddingBottom: 0,
                                                alignSelf: "center",
                                            }}
                                        >
                                            {
                                                userSelectedOrganizationReports[
                                                    reportId
                                                ]?.related[0]
                                                    .ORGANIZATIONGROUPNAME
                                            }
                                        </label>
                                    </>
                                )}
                        </div>

                        {(organizations.length > 1 ||
                            userSelectedOrganizations[id]?.related.length >
                                0) && (
                            <div style={{ marginTop: 20 }}>
                                <OrganizationSearchBaseComponent
                                    organizations={organizations}
                                    allOrganizations={allOrganizations}
                                    policies={policies}
                                    totalParentOrganizations={
                                        totalParentOrganizations
                                    }
                                    organizationPreference={
                                        organizationPreference
                                    }
                                    userSelectedOrganizations={
                                        userSelectedOrganizations
                                    }
                                    organizationsCount={organizationsCount}
                                    organizationSelected={organizationSelected}
                                    onSelectedRelatedOrganizationsChange={
                                        handleSelectedRelatedOrganizations
                                    }
                                    clearButtonText="Clear selection"
                                />
                            </div>
                        )}
                    </div>
                    <div>
                        <ReportViews
                            displayReportView={displayReportView}
                            organizations={organizations}
                            user={user}
                        />
                    </div>
                </>
            ) : (
                <div className="reportpage">
                    <div>
                        A new<b> reports</b> feature is coming soon... In the
                        meantime <br />
                        please continue to access <b>reports</b> through{" "}
                        <span
                            className="cursor-pointer"
                            onClick={handleClick}
                            onKeyDown={handleClick}
                        >
                            Claim & Leave InSight (CLI).
                        </span>
                    </div>
                </div>
            )}
        </>
    );
}

export default ViewSectionPage;
